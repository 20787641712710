const testTrainData = {
    state: {
        trainData:[
            // 课目一
            {
                "name":"课目一训练",
                "data":{
                    "name":"课目一训练",
                    "class":{
                        "name":"一班",
                        "children":[
            
                        ],
                        "id":"d77edc08-f5a4-4b6d-8f9f-8910ead6fcf7"
                    },
                    "site":{
                        "number":"1",
                        "name":"训练场1"
                    },
                    "tran":{
                        "type":"模式一",
                        "key":"1",
                        "value":"单人固定点位对运动人体部位射击",
                        "children":[
            
                        ],
                        "id":"dc7edc08-da6b-4348-8100-246acdcc998e"
                    },
                    "timeRange":[
                        "2024-05-16 08:00:00",
                        "2024-05-16 10:00:00"
                    ],
                    "peopleTeam":{
                        "name":"参与人员",
                        "teamResult":[
            
                        ],
                        "people":[
                            {
                                "id":"A1",
                                "nameId":"d77edc08-bcfc-4ac0-8d70-f5b6e3d9ca29",
                                "name":"张三红",
                                "result":[
            
                                ]
                            },
                            {
                                "id":"A2",
                                "nameId":"d87edc08-e10d-4550-8455-71749c8cbdd0",
                                "name":"李四红",
                                "result":[
            
                                ]
                            }
                        ]
                    },
                    "machine":{
                        "name":"机器信息",
                        "teamResult":[
            
                        ],
                        "people":[
                            {
                                "id":"B1",
                                "name":"机器一"
                            },
                            {
                                "id":"B2",
                                "name":"机器二"
                            },
                            {
                                "id":"B3",
                                "name":"机器三"
                            },
                            {
                                "id":"B4",
                                "name":"机器四"
                            }
                        ]
                    },
                    "description":"课目一的训练"
                }
            },
            // 课目二
            {
                "name":"课目二训练",
                "data":{
                    "name":"课目二训练",
                    "class":{
                        "name":"一班",
                        "children":[
            
                        ],
                        "id":"d77edc08-f5a4-4b6d-8f9f-8910ead6fcf7"
                    },
                    "site":{
                        "number":"2",
                        "name":"训练场2"
                    },
                    "tran":{
                        "type":"模式一",
                        "key":"2",
                        "value":"编队固定点位对运动人体部位射击",
                        "children":[
            
                        ],
                        "id":"dc7edc08-f98a-4379-8205-2d1863c3d449"
                    },
                    "timeRange":[
                        "2024-05-24 09:00:00",
                        "2024-05-24 14:00:00"
                    ],
                    "peopleTeam":{
                        "name":"红队",
                        "teamResult":[
                            {
                                "id":"B1",
                                "name":"机器一",
                                "children":[
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":100,
                                        "dataNumber":0,
                                        "id":"B1",
                                        "number":"王大力蓝",
                                        "time":1702957202,
                                        "default1":"A1",
                                        "default2":"",
                                        "default3":"",
                                        "default4":"",
                                        "default5":"",
                                        "default6":1,
                                        "enemy":{
                                            "id":"B1",
                                            "name":"机器一"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":100,
                                        "dataNumber":1,
                                        "id":"B1",
                                        "number":"王大力蓝",
                                        "time":1702957211,
                                        "default1":"",
                                        "default2":"A1",
                                        "default3":"",
                                        "default4":"",
                                        "default5":"",
                                        "default6":2,
                                        "enemy":{
                                            "id":"B1",
                                            "name":"机器一"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":80,
                                        "dataNumber":2,
                                        "id":"B1",
                                        "number":"王大力蓝",
                                        "time":1702957227,
                                        "default1":"",
                                        "default2":"",
                                        "default3":"A1",
                                        "default4":"0",
                                        "default5":"7",
                                        "default6":3,
                                        "enemy":{
                                            "id":"B1",
                                            "name":"机器一"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":20,
                                        "dataNumber":3,
                                        "id":"B1",
                                        "number":"王大力蓝",
                                        "time":1702957232,
                                        "default1":"",
                                        "default2":"",
                                        "default3":"A1",
                                        "default4":"0",
                                        "default5":"9",
                                        "default6":3,
                                        "enemy":{
                                            "id":"B1",
                                            "name":"机器一"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":0,
                                        "dataNumber":4,
                                        "id":"B1",
                                        "number":"王大力蓝",
                                        "time":1702957237,
                                        "default1":"",
                                        "default2":"",
                                        "default3":"A1",
                                        "default4":"0",
                                        "default5":"9",
                                        "default6":3,
                                        "enemy":{
                                            "id":"B1",
                                            "name":"机器一"
                                        }
                                    }
                                ]
                            },
                            {
                                "id":"B2",
                                "name":"机器二",
                                "children":[
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":100,
                                        "dataNumber":5,
                                        "id":"B2",
                                        "number":"李一蓝",
                                        "time":1702957251,
                                        "default1":"A2",
                                        "default2":"",
                                        "default3":"",
                                        "default4":"",
                                        "default5":"",
                                        "default6":1,
                                        "enemy":{
                                            "id":"B2",
                                            "name":"机器二"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":100,
                                        "dataNumber":6,
                                        "id":"B2",
                                        "number":"李一蓝",
                                        "time":1702957257,
                                        "default1":"",
                                        "default2":"A2",
                                        "default3":"",
                                        "default4":"",
                                        "default5":"",
                                        "default6":2,
                                        "enemy":{
                                            "id":"B2",
                                            "name":"机器二"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":40,
                                        "dataNumber":7,
                                        "id":"B2",
                                        "number":"李一蓝",
                                        "time":1702957277,
                                        "default1":"",
                                        "default2":"",
                                        "default3":"A2",
                                        "default4":"0",
                                        "default5":"8",
                                        "default6":3,
                                        "enemy":{
                                            "id":"B2",
                                            "name":"机器二"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":10,
                                        "dataNumber":8,
                                        "id":"B2",
                                        "number":"李一蓝",
                                        "time":1702957292,
                                        "default1":"",
                                        "default2":"",
                                        "default3":"A2",
                                        "default4":"0",
                                        "default5":"6",
                                        "default6":3,
                                        "enemy":{
                                            "id":"B2",
                                            "name":"机器二"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":0,
                                        "dataNumber":9,
                                        "id":"B2",
                                        "number":"李一蓝",
                                        "time":1702957297,
                                        "default1":"",
                                        "default2":"",
                                        "default3":"A2",
                                        "default4":"0",
                                        "default5":"9",
                                        "default6":3,
                                        "enemy":{
                                            "id":"B2",
                                            "name":"机器二"
                                        }
                                    }
                                ]
                            },
                            {
                                "id":"B3",
                                "name":"机器三",
                                "children":[
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":100,
                                        "dataNumber":10,
                                        "id":"B3",
                                        "number":"张光蓝",
                                        "time":1702957322,
                                        "default1":"A1",
                                        "default2":"",
                                        "default3":"",
                                        "default4":"",
                                        "default5":"",
                                        "default6":1,
                                        "enemy":{
                                            "id":"B3",
                                            "name":"机器三"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":100,
                                        "dataNumber":11,
                                        "id":"B3",
                                        "number":"张光蓝",
                                        "time":1702957337,
                                        "default1":"",
                                        "default2":"A2",
                                        "default3":"",
                                        "default4":"",
                                        "default5":"",
                                        "default6":2,
                                        "enemy":{
                                            "id":"B3",
                                            "name":"机器三"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":90,
                                        "dataNumber":12,
                                        "id":"B3",
                                        "number":"张光蓝",
                                        "time":1702957377,
                                        "default1":"",
                                        "default2":"",
                                        "default3":"A1",
                                        "default4":"0",
                                        "default5":"3",
                                        "default6":3,
                                        "enemy":{
                                            "id":"B3",
                                            "name":"机器三"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":60,
                                        "dataNumber":13,
                                        "id":"B3",
                                        "number":"张光蓝",
                                        "time":1702957392,
                                        "default1":"",
                                        "default2":"",
                                        "default3":"A2",
                                        "default4":"0",
                                        "default5":"3",
                                        "default6":3,
                                        "enemy":{
                                            "id":"B3",
                                            "name":"机器三"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":0,
                                        "dataNumber":14,
                                        "id":"B3",
                                        "number":"张光蓝",
                                        "time":1702957397,
                                        "default1":"",
                                        "default2":"",
                                        "default3":"A2",
                                        "default4":"0",
                                        "default5":"8",
                                        "default6":3,
                                        "enemy":{
                                            "id":"B3",
                                            "name":"机器三"
                                        }
                                    }
                                ]
                            },
                            {
                                "id":"B4",
                                "name":"机器四",
                                "children":[
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":100,
                                        "dataNumber":15,
                                        "id":"B4",
                                        "number":"何亮蓝",
                                        "time":1702957422,
                                        "default1":"A2",
                                        "default2":"",
                                        "default3":"",
                                        "default4":"",
                                        "default5":"",
                                        "default6":1,
                                        "enemy":{
                                            "id":"B4",
                                            "name":"机器四"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":100,
                                        "dataNumber":16,
                                        "id":"B4",
                                        "number":"何亮蓝",
                                        "time":1702957437,
                                        "default1":"",
                                        "default2":"A1",
                                        "default3":"",
                                        "default4":"",
                                        "default5":"",
                                        "default6":2,
                                        "enemy":{
                                            "id":"B4",
                                            "name":"机器四"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":85,
                                        "dataNumber":17,
                                        "id":"B4",
                                        "number":"何亮蓝",
                                        "time":1702957457,
                                        "default1":"",
                                        "default2":"",
                                        "default3":"A2",
                                        "default4":"0",
                                        "default5":"5",
                                        "default6":3,
                                        "enemy":{
                                            "id":"B4",
                                            "name":"机器四"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":70,
                                        "dataNumber":18,
                                        "id":"B4",
                                        "number":"何亮蓝",
                                        "time":1702957492,
                                        "default1":"",
                                        "default2":"",
                                        "default3":"A1",
                                        "default4":"0",
                                        "default5":"3",
                                        "default6":3,
                                        "enemy":{
                                            "id":"B4",
                                            "name":"机器四"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":0,
                                        "dataNumber":19,
                                        "id":"B4",
                                        "number":"何亮蓝",
                                        "time":1702957497,
                                        "default1":"",
                                        "default2":"",
                                        "default3":"A1",
                                        "default4":"0",
                                        "default5":"9",
                                        "default6":3,
                                        "enemy":{
                                            "id":"B4",
                                            "name":"机器四"
                                        }
                                    }
                                ]
                            },
                            {
                                "id":"B5",
                                "name":"机器五",
                                "children":[
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":100,
                                        "dataNumber":20,
                                        "id":"B5",
                                        "number":"何亮蓝",
                                        "time":1702957507,
                                        "default1":"A3",
                                        "default2":"",
                                        "default3":"",
                                        "default4":"",
                                        "default5":"",
                                        "default6":1,
                                        "enemy":{
                                            "id":"B5",
                                            "name":"机器五"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":100,
                                        "dataNumber":21,
                                        "id":"B5",
                                        "number":"何亮蓝",
                                        "time":1702957517,
                                        "default1":"",
                                        "default2":"A3",
                                        "default3":"",
                                        "default4":"",
                                        "default5":"",
                                        "default6":2,
                                        "enemy":{
                                            "id":"B5",
                                            "name":"机器五"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":20,
                                        "dataNumber":22,
                                        "id":"B5",
                                        "number":"何亮蓝",
                                        "time":1702957527,
                                        "default1":"",
                                        "default2":"",
                                        "default3":"A3",
                                        "default4":"0",
                                        "default5":"9",
                                        "default6":3,
                                        "enemy":{
                                            "id":"B5",
                                            "name":"机器五"
                                        }
                                    },
                                    {
                                        "addressCode":"1",
                                        "bloodVolume":0,
                                        "dataNumber":22,
                                        "id":"B5",
                                        "number":"何亮蓝",
                                        "time":1702957527,
                                        "default1":"",
                                        "default2":"",
                                        "default3":"A2",
                                        "default4":"0",
                                        "default5":"7",
                                        "default6":3,
                                        "enemy":{
                                            "id":"B5",
                                            "name":"机器五"
                                        }
                                    }
                                ]
                            }
                        ],
                        "people":[
                            {
                                "id":"A1",
                                "nameId":"d77edc08-bcfc-4ac0-8d70-f5b6e3d9ca29",
                                "name":"张三红",
                                "result":[
                                    {
                                        "id":"B1",
                                        "name":"机器一",
                                        "children":[
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":100,
                                                "dataNumber":0,
                                                "id":"B1",
                                                "number":"王大力蓝",
                                                "time":1702957202,
                                                "default1":"A1",
                                                "default2":"",
                                                "default3":"",
                                                "default4":"",
                                                "default5":"",
                                                "default6":1,
                                                "enemy":{
                                                    "id":"B1",
                                                    "name":"机器一"
                                                }
                                            },
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":100,
                                                "dataNumber":1,
                                                "id":"B1",
                                                "number":"王大力蓝",
                                                "time":1702957211,
                                                "default1":"",
                                                "default2":"A1",
                                                "default3":"",
                                                "default4":"",
                                                "default5":"",
                                                "default6":2,
                                                "enemy":{
                                                    "id":"B1",
                                                    "name":"机器一"
                                                }
                                            },
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":80,
                                                "dataNumber":2,
                                                "id":"B1",
                                                "number":"王大力蓝",
                                                "time":1702957227,
                                                "default1":"",
                                                "default2":"",
                                                "default3":"A1",
                                                "default4":"0",
                                                "default5":"7",
                                                "default6":3,
                                                "enemy":{
                                                    "id":"B1",
                                                    "name":"机器一"
                                                }
                                            },
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":20,
                                                "dataNumber":3,
                                                "id":"B1",
                                                "number":"王大力蓝",
                                                "time":1702957232,
                                                "default1":"",
                                                "default2":"",
                                                "default3":"A1",
                                                "default4":"0",
                                                "default5":"9",
                                                "default6":3,
                                                "enemy":{
                                                    "id":"B1",
                                                    "name":"机器一"
                                                }
                                            },
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":0,
                                                "dataNumber":4,
                                                "id":"B1",
                                                "number":"王大力蓝",
                                                "time":1702957237,
                                                "default1":"",
                                                "default2":"",
                                                "default3":"A1",
                                                "default4":"0",
                                                "default5":"9",
                                                "default6":3,
                                                "enemy":{
                                                    "id":"B1",
                                                    "name":"机器一"
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        "id":"B3",
                                        "name":"机器三",
                                        "children":[
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":100,
                                                "dataNumber":10,
                                                "id":"B3",
                                                "number":"张光蓝",
                                                "time":1702957322,
                                                "default1":"A1",
                                                "default2":"",
                                                "default3":"",
                                                "default4":"",
                                                "default5":"",
                                                "default6":1,
                                                "enemy":{
                                                    "id":"B3",
                                                    "name":"机器三"
                                                }
                                            },
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":90,
                                                "dataNumber":12,
                                                "id":"B3",
                                                "number":"张光蓝",
                                                "time":1702957377,
                                                "default1":"",
                                                "default2":"",
                                                "default3":"A1",
                                                "default4":"0",
                                                "default5":"3",
                                                "default6":3,
                                                "enemy":{
                                                    "id":"B3",
                                                    "name":"机器三"
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        "id":"B4",
                                        "name":"机器四",
                                        "children":[
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":100,
                                                "dataNumber":16,
                                                "id":"B4",
                                                "number":"何亮蓝",
                                                "time":1702957437,
                                                "default1":"",
                                                "default2":"A1",
                                                "default3":"",
                                                "default4":"",
                                                "default5":"",
                                                "default6":2,
                                                "enemy":{
                                                    "id":"B4",
                                                    "name":"机器四"
                                                }
                                            },
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":70,
                                                "dataNumber":18,
                                                "id":"B4",
                                                "number":"何亮蓝",
                                                "time":1702957492,
                                                "default1":"",
                                                "default2":"",
                                                "default3":"A1",
                                                "default4":"0",
                                                "default5":"3",
                                                "default6":3,
                                                "enemy":{
                                                    "id":"B4",
                                                    "name":"机器四"
                                                }
                                            },
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":0,
                                                "dataNumber":19,
                                                "id":"B4",
                                                "number":"何亮蓝",
                                                "time":1702957497,
                                                "default1":"",
                                                "default2":"",
                                                "default3":"A1",
                                                "default4":"0",
                                                "default5":"9",
                                                "default6":3,
                                                "enemy":{
                                                    "id":"B4",
                                                    "name":"机器四"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "id":"A2",
                                "nameId":"d87edc08-e10d-4550-8455-71749c8cbdd0",
                                "name":"李四红",
                                "result":[
                                    {
                                        "id":"B2",
                                        "name":"机器二",
                                        "children":[
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":100,
                                                "dataNumber":5,
                                                "id":"B2",
                                                "number":"李一蓝",
                                                "time":1702957251,
                                                "default1":"A2",
                                                "default2":"",
                                                "default3":"",
                                                "default4":"",
                                                "default5":"",
                                                "default6":1,
                                                "enemy":{
                                                    "id":"B2",
                                                    "name":"机器二"
                                                }
                                            },
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":100,
                                                "dataNumber":6,
                                                "id":"B2",
                                                "number":"李一蓝",
                                                "time":1702957257,
                                                "default1":"",
                                                "default2":"A2",
                                                "default3":"",
                                                "default4":"",
                                                "default5":"",
                                                "default6":2,
                                                "enemy":{
                                                    "id":"B2",
                                                    "name":"机器二"
                                                }
                                            },
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":40,
                                                "dataNumber":7,
                                                "id":"B2",
                                                "number":"李一蓝",
                                                "time":1702957277,
                                                "default1":"",
                                                "default2":"",
                                                "default3":"A2",
                                                "default4":"0",
                                                "default5":"8",
                                                "default6":3,
                                                "enemy":{
                                                    "id":"B2",
                                                    "name":"机器二"
                                                }
                                            },
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":10,
                                                "dataNumber":8,
                                                "id":"B2",
                                                "number":"李一蓝",
                                                "time":1702957292,
                                                "default1":"",
                                                "default2":"",
                                                "default3":"A2",
                                                "default4":"0",
                                                "default5":"6",
                                                "default6":3,
                                                "enemy":{
                                                    "id":"B2",
                                                    "name":"机器二"
                                                }
                                            },
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":0,
                                                "dataNumber":9,
                                                "id":"B2",
                                                "number":"李一蓝",
                                                "time":1702957297,
                                                "default1":"",
                                                "default2":"",
                                                "default3":"A2",
                                                "default4":"0",
                                                "default5":"9",
                                                "default6":3,
                                                "enemy":{
                                                    "id":"B2",
                                                    "name":"机器二"
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        "id":"B3",
                                        "name":"机器三",
                                        "children":[
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":100,
                                                "dataNumber":11,
                                                "id":"B3",
                                                "number":"张光蓝",
                                                "time":1702957337,
                                                "default1":"",
                                                "default2":"A2",
                                                "default3":"",
                                                "default4":"",
                                                "default5":"",
                                                "default6":2,
                                                "enemy":{
                                                    "id":"B3",
                                                    "name":"机器三"
                                                }
                                            },
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":60,
                                                "dataNumber":13,
                                                "id":"B3",
                                                "number":"张光蓝",
                                                "time":1702957392,
                                                "default1":"",
                                                "default2":"",
                                                "default3":"A2",
                                                "default4":"0",
                                                "default5":"3",
                                                "default6":3,
                                                "enemy":{
                                                    "id":"B3",
                                                    "name":"机器三"
                                                }
                                            },
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":0,
                                                "dataNumber":14,
                                                "id":"B3",
                                                "number":"张光蓝",
                                                "time":1702957397,
                                                "default1":"",
                                                "default2":"",
                                                "default3":"A2",
                                                "default4":"0",
                                                "default5":"8",
                                                "default6":3,
                                                "enemy":{
                                                    "id":"B3",
                                                    "name":"机器三"
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        "id":"B4",
                                        "name":"机器四",
                                        "children":[
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":100,
                                                "dataNumber":15,
                                                "id":"B4",
                                                "number":"何亮蓝",
                                                "time":1702957422,
                                                "default1":"A2",
                                                "default2":"",
                                                "default3":"",
                                                "default4":"",
                                                "default5":"",
                                                "default6":1,
                                                "enemy":{
                                                    "id":"B4",
                                                    "name":"机器四"
                                                }
                                            },
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":85,
                                                "dataNumber":17,
                                                "id":"B4",
                                                "number":"何亮蓝",
                                                "time":1702957457,
                                                "default1":"",
                                                "default2":"",
                                                "default3":"A2",
                                                "default4":"0",
                                                "default5":"5",
                                                "default6":3,
                                                "enemy":{
                                                    "id":"B4",
                                                    "name":"机器四"
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        "id":"B5",
                                        "name":"机器五",
                                        "children":[
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":0,
                                                "dataNumber":22,
                                                "id":"B5",
                                                "number":"何亮蓝",
                                                "time":1702957527,
                                                "default1":"",
                                                "default2":"",
                                                "default3":"A2",
                                                "default4":"0",
                                                "default5":"7",
                                                "default6":3,
                                                "enemy":{
                                                    "id":"B5",
                                                    "name":"机器五"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "id":"A3",
                                "nameId":"d87edc08-9717-4e1d-8c0b-08dbceb2b5c3",
                                "name":"小明红",
                                "result":[
                                    {
                                        "id":"B5",
                                        "name":"机器五",
                                        "children":[
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":100,
                                                "dataNumber":20,
                                                "id":"B5",
                                                "number":"何亮蓝",
                                                "time":1702957507,
                                                "default1":"A3",
                                                "default2":"",
                                                "default3":"",
                                                "default4":"",
                                                "default5":"",
                                                "default6":1,
                                                "enemy":{
                                                    "id":"B5",
                                                    "name":"机器五"
                                                }
                                            },
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":100,
                                                "dataNumber":21,
                                                "id":"B5",
                                                "number":"何亮蓝",
                                                "time":1702957517,
                                                "default1":"",
                                                "default2":"A3",
                                                "default3":"",
                                                "default4":"",
                                                "default5":"",
                                                "default6":2,
                                                "enemy":{
                                                    "id":"B5",
                                                    "name":"机器五"
                                                }
                                            },
                                            {
                                                "addressCode":"1",
                                                "bloodVolume":20,
                                                "dataNumber":22,
                                                "id":"B5",
                                                "number":"何亮蓝",
                                                "time":1702957527,
                                                "default1":"",
                                                "default2":"",
                                                "default3":"A3",
                                                "default4":"0",
                                                "default5":"9",
                                                "default6":3,
                                                "enemy":{
                                                    "id":"B5",
                                                    "name":"机器五"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    "machine":{
                        "name":"机器信息",
                        "teamResult":[
            
                        ],
                        "people":[
                            {
                                "id":"B1",
                                "name":"机器一"
                            },
                            {
                                "id":"B2",
                                "name":"机器二"
                            },
                            {
                                "id":"B3",
                                "name":"机器三"
                            },
                            {
                                "id":"B4",
                                "name":"机器四"
                            },
                            {
                                "id":"B5",
                                "name":"机器五"
                            }
                        ]
                    },
                    "description":"课目二训练"
                }
            },
            // 课目三
            {
                "name":"课目三训练",
                "data":{
                    "name":"课目三训练",
                    "class":{
                        "name":"一班",
                        "children":[
            
                        ],
                        "id":"d77edc08-f5a4-4b6d-8f9f-8910ead6fcf7"
                    },
                    "site":{
                        "number":"2",
                        "name":"训练场2"
                    },
                    "tran":{
                        "type":"模式一",
                        "key":"3",
                        "value":"编队固定点位对运动人体部位射击指挥训练",
                        "children":[
            
                        ],
                        "id":"dc7edc08-9d9c-4a4c-8a01-df1a8431b144"
                    },
                    "timeRange":[
                        "2024-05-17 14:00:00",
                        "2024-05-17 18:30:00"
                    ],
                    "peopleTeam":{
                        "name":"红队",
                        "teamResult":[
            
                        ],
                        "people":[
                            {
                                "id":"A1",
                                "nameId":"d87edc08-9717-4e1d-8c0b-08dbceb2b5c3",
                                "name":"小明红",
                                "result":[
            
                                ]
                            },
                            {
                                "id":"A2",
                                "nameId":"d87edc08-bb23-4848-8610-2b31103b6b08",
                                "name":"王五红",
                                "result":[
            
                                ]
                            },
                            {
                                "id":"A3",
                                "nameId":"d87edc08-1a3a-4bf3-8e0e-dcb1bbd6803f",
                                "name":"刘先进红",
                                "result":[
            
                                ]
                            }
                        ]
                    },
                    "machine":{
                        "name":"机器信息",
                        "teamResult":[
            
                        ],
                        "people":[
                            {
                                "id":"B1",
                                "name":"靶机1"
                            },
                            {
                                "id":"B2",
                                "name":"靶机2"
                            },
                            {
                                "id":"B3",
                                "name":"靶机3"
                            },
                            {
                                "id":"B4",
                                "name":"靶机4"
                            },
                            {
                                "id":"B5",
                                "name":"靶机5"
                            }
                        ]
                    },
                    "description":"课目三训练"
                }
            },
            // 课目四
            {
                "name":"课目四训练",
                "data":{
                    "name":"课目四训练",
                    "class":{
                        "name":"一班",
                        "children":[
            
                        ],
                        "id":"d77edc08-f5a4-4b6d-8f9f-8910ead6fcf7"
                    },
                    "site":{
                        "number":"4",
                        "name":"训练场4"
                    },
                    "tran":{
                        "type":"模式二",
                        "key":"4",
                        "value":"单人移动对抗射击",
                        "children":[
            
                        ],
                        "id":"dc7edc08-16b6-468c-8833-bbe6e2cdb676"
                    },
                    "timeRange":[
                        "2024-05-22 09:00:00",
                        "2024-05-22 12:00:00"
                    ],
                    "peopleTeam":{
                        "name":"参与人员",
                        "teamResult":[
            
                        ],
                        "people":[
                            {
                                "id":"A1",
                                "nameId":"d77edc08-bcfc-4ac0-8d70-f5b6e3d9ca29",
                                "name":"张三红",
                                "result":[
            
                                ]
                            },
                            {
                                "id":"A2",
                                "nameId":"d87edc08-e10d-4550-8455-71749c8cbdd0",
                                "name":"李四红",
                                "result":[
            
                                ]
                            }
                        ]
                    },
                    "machine":{
                        "name":"机器信息",
                        "teamResult":[
            
                        ],
                        "people":[
                            {
                                "id":"B1",
                                "name":"机器1"
                            },
                            {
                                "id":"B2",
                                "name":"机器2"
                            },
                            {
                                "id":"B3",
                                "name":"机器3"
                            },
                            {
                                "id":"B4",
                                "name":"机器4"
                            }
                        ]
                    },
                    "description":"课目四训练"
                }
            },
            // 课目五
            {
                "name":"课目五训练",
                "data":{
                    "name":"课目五训练",
                    "class":{
                        "name":"一班",
                        "children":[
            
                        ],
                        "id":"d77edc08-f5a4-4b6d-8f9f-8910ead6fcf7"
                    },
                    "site":{
                        "number":"4",
                        "name":"训练场4"
                    },
                    "tran":{
                        "type":"模式二",
                        "key":"5",
                        "value":"编队移动对抗射击",
                        "children":[
            
                        ],
                        "id":"dc7edc08-c8c5-4400-8e95-282e00b07fae"
                    },
                    "timeRange":[
                        "2024-05-16 15:00:00",
                        "2024-05-16 18:00:00"
                    ],
                    "peopleTeam":{
                        "name":"红队",
                        "teamResult":[
            
                        ],
                        "people":[
                            {
                                "id":"A1",
                                "nameId":"d87edc08-9717-4e1d-8c0b-08dbceb2b5c3",
                                "name":"小明红",
                                "result":[
            
                                ]
                            },
                            {
                                "id":"A2",
                                "nameId":"d87edc08-bb23-4848-8610-2b31103b6b08",
                                "name":"王五红",
                                "result":[
            
                                ]
                            },
                            {
                                "id":"A3",
                                "nameId":"d77edc08-bcfc-4ac0-8d70-f5b6e3d9ca29",
                                "name":"张三红",
                                "result":[
            
                                ]
                            }
                        ]
                    },
                    "machine":{
                        "name":"机器信息",
                        "teamResult":[
            
                        ],
                        "people":[
                            {
                                "id":"B1",
                                "name":"机器1"
                            },
                            {
                                "id":"B2",
                                "name":"机器2"
                            },
                            {
                                "id":"B3",
                                "name":"机器3"
                            },
                            {
                                "id":"B4",
                                "name":"机器4"
                            },
                            {
                                "id":"B5",
                                "name":"机器5"
                            }
                        ]
                    },
                    "description":"课目五训练"
                }
            },
            // 课目六
            {
                "name":"课目六训练",
                "data":{
                    "name":"课目六训练",
                    "class":{
                        "name":"一班",
                        "children":[
            
                        ],
                        "id":"d77edc08-f5a4-4b6d-8f9f-8910ead6fcf7"
                    },
                    "site":{
                        "number":"5",
                        "name":"训练场5"
                    },
                    "tran":{
                        "type":"模式二",
                        "key":"6",
                        "value":"编队移动对抗射击指挥训练",
                        "children":[
            
                        ],
                        "id":"dc7edc08-bed9-405c-85c3-5b3801311611"
                    },
                    "timeRange":[
                        "2024-05-19 08:00:00",
                        "2024-05-20 20:00:00"
                    ],
                    "peopleTeam":{
                        "name":"红队",
                        "teamResult":[
            
                        ],
                        "people":[
                            {
                                "id":"A1",
                                "nameId":"d87edc08-bb23-4848-8610-2b31103b6b08",
                                "name":"王五红",
                                "result":[
            
                                ]
                            },
                            {
                                "id":"A2",
                                "nameId":"d77edc08-bcfc-4ac0-8d70-f5b6e3d9ca29",
                                "name":"张三红",
                                "result":[
            
                                ]
                            },
                            {
                                "id":"A3",
                                "nameId":"d87edc08-e10d-4550-8455-71749c8cbdd0",
                                "name":"李四红",
                                "result":[
            
                                ]
                            }
                        ]
                    },
                    "machine":{
                        "name":"机器信息",
                        "teamResult":[
            
                        ],
                        "people":[
                            {
                                "id":"B1",
                                "name":"机器1"
                            },
                            {
                                "id":"B2",
                                "name":"机器2"
                            },
                            {
                                "id":"B3",
                                "name":"机器3"
                            },
                            {
                                "id":"B4",
                                "name":"机器4"
                            },
                            {
                                "id":"B5",
                                "name":"机器5"
                            }
                        ]
                    },
                    "description":"课目六训练"
                }
            },
            // 课目七
            {
                "name":"课目七训练",
                "data":{
                    "name":"课目七训练",
                    "class":{
                        "name":"一班",
                        "children":[
            
                        ],
                        "id":"d77edc08-f5a4-4b6d-8f9f-8910ead6fcf7"
                    },
                    "site":{
                        "number":"3",
                        "name":"训练场3"
                    },
                    "tran":{
                        "type":"模式三",
                        "key":"7",
                        "value":"单人1V1对抗模拟射击",
                        "children":[
            
                        ],
                        "id":"dc7edc08-35e8-416a-807c-fbd718723be4"
                    },
                    "timeRange":[
                        "2024-05-23 08:00:00",
                        "2024-05-23 10:00:00"
                    ],
                    "peopleTeam":{
                        "name":"红方",
                        "teamResult":[
            
                        ],
                        "people":[
                            {
                                "id":"A1",
                                "nameId":"d77edc08-bcfc-4ac0-8d70-f5b6e3d9ca29",
                                "name":"张三红",
                                "result":[
            
                                ]
                            }
                        ]
                    },
                    "machine":{
                        "name":"蓝方",
                        "teamResult":[
            
                        ],
                        "people":[
                            {
                                "id":"B1",
                                "nameId":"d87edc08-5466-4941-84c6-7d686f80db78",
                                "name":"李一蓝",
                                "result":[
            
                                ]
                            }
                        ]
                    },
                    "description":"课目七训练"
                }
            },
            // 课目八
            {
                "name": "课目八训练",
                "data": {
                    "name": "课目八训练",
                    "class": {
                        "name": "一班",
                        "children": [
                            
                        ],
                        "id": "d77edc08-f5a4-4b6d-8f9f-8910ead6fcf7"
                    },
                    "site": {
                        "number": "5",
                        "name": "训练场5"
                    },
                    "tran": {
                        "type": "模式三",
                        "key": "8",
                        "value": "编队5V5对抗模拟射击",
                        "children": [
                            
                        ],
                        "id": "dc7edc08-1efd-433f-8b24-32558b6055f4"
                    },
                    "timeRange": [
                        "2024-05-20 00:00:00",
                        "2024-05-20 10:59:59"
                    ],
                    "peopleTeam": {
                        "name": "红队",
                        "teamResult": [
                            {
                                "id": "B1",
                                "name": "王大力蓝",
                                "children": [
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 0,
                                        "id": "B1",
                                        "number": "王大力蓝",
                                        "time": 1702957202,
                                        "default1": "A1",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "B1",
                                            "name": "王大力蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 1,
                                        "id": "B1",
                                        "number": "王大力蓝",
                                        "time": 1702957211,
                                        "default1": "",
                                        "default2": "A2",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "B1",
                                            "name": "王大力蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 80,
                                        "dataNumber": 2,
                                        "id": "B1",
                                        "number": "王大力蓝",
                                        "time": 1702957227,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A1",
                                        "default4": "1",
                                        "default5": "7",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B1",
                                            "name": "王大力蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 20,
                                        "dataNumber": 3,
                                        "id": "B1",
                                        "number": "王大力蓝",
                                        "time": 1702957232,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A2",
                                        "default4": "1",
                                        "default5": "9",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B1",
                                            "name": "王大力蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 0,
                                        "dataNumber": 4,
                                        "id": "B1",
                                        "number": "王大力蓝",
                                        "time": 1702957237,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A1",
                                        "default4": "1",
                                        "default5": "9",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B1",
                                            "name": "王大力蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 0,
                                        "id": "B1",
                                        "number": "王大力蓝",
                                        "time": 1702957202,
                                        "default1": "A1",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "B1",
                                            "name": "王大力蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 1,
                                        "id": "B1",
                                        "number": "王大力蓝",
                                        "time": 1702957211,
                                        "default1": "",
                                        "default2": "A2",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "B1",
                                            "name": "王大力蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 80,
                                        "dataNumber": 2,
                                        "id": "B1",
                                        "number": "王大力蓝",
                                        "time": 1702957227,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A1",
                                        "default4": "1",
                                        "default5": "7",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B1",
                                            "name": "王大力蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 20,
                                        "dataNumber": 3,
                                        "id": "B1",
                                        "number": "王大力蓝",
                                        "time": 1702957232,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A2",
                                        "default4": "1",
                                        "default5": "9",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B1",
                                            "name": "王大力蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 0,
                                        "dataNumber": 4,
                                        "id": "B1",
                                        "number": "王大力蓝",
                                        "time": 1702957237,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A1",
                                        "default4": "1",
                                        "default5": "9",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B1",
                                            "name": "王大力蓝"
                                        }
                                    }
                                ]
                            },
                            {
                                "id": "B2",
                                "name": "李一蓝",
                                "children": [
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 5,
                                        "id": "B2",
                                        "number": "李一蓝",
                                        "time": 1702957251,
                                        "default1": "A2",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "B2",
                                            "name": "李一蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 6,
                                        "id": "B2",
                                        "number": "李一蓝",
                                        "time": 1702957257,
                                        "default1": "",
                                        "default2": "A1",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "B2",
                                            "name": "李一蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 40,
                                        "dataNumber": 7,
                                        "id": "B2",
                                        "number": "李一蓝",
                                        "time": 1702957277,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A2",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B2",
                                            "name": "李一蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 10,
                                        "dataNumber": 8,
                                        "id": "B2",
                                        "number": "李一蓝",
                                        "time": 1702957292,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A1",
                                        "default4": "1",
                                        "default5": "6",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B2",
                                            "name": "李一蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 0,
                                        "dataNumber": 9,
                                        "id": "B2",
                                        "number": "李一蓝",
                                        "time": 1702957297,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A2",
                                        "default4": "1",
                                        "default5": "9",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B2",
                                            "name": "李一蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 5,
                                        "id": "B2",
                                        "number": "李一蓝",
                                        "time": 1702957251,
                                        "default1": "A2",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "B2",
                                            "name": "李一蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 6,
                                        "id": "B2",
                                        "number": "李一蓝",
                                        "time": 1702957257,
                                        "default1": "",
                                        "default2": "A1",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "B2",
                                            "name": "李一蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 40,
                                        "dataNumber": 7,
                                        "id": "B2",
                                        "number": "李一蓝",
                                        "time": 1702957277,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A2",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B2",
                                            "name": "李一蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 10,
                                        "dataNumber": 8,
                                        "id": "B2",
                                        "number": "李一蓝",
                                        "time": 1702957292,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A1",
                                        "default4": "1",
                                        "default5": "6",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B2",
                                            "name": "李一蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 0,
                                        "dataNumber": 9,
                                        "id": "B2",
                                        "number": "李一蓝",
                                        "time": 1702957297,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A2",
                                        "default4": "1",
                                        "default5": "9",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B2",
                                            "name": "李一蓝"
                                        }
                                    }
                                ]
                            },
                            {
                                "id": "B3",
                                "name": "张光蓝",
                                "children": [
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 5,
                                        "id": "B3",
                                        "number": "李一蓝",
                                        "time": 1702957251,
                                        "default1": "A2",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "B3",
                                            "name": "张光蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 6,
                                        "id": "B3",
                                        "number": "李一蓝",
                                        "time": 1702957257,
                                        "default1": "",
                                        "default2": "A3",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "B3",
                                            "name": "张光蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 40,
                                        "dataNumber": 7,
                                        "id": "B3",
                                        "number": "李一蓝",
                                        "time": 1702957277,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A3",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B3",
                                            "name": "张光蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 10,
                                        "dataNumber": 8,
                                        "id": "B3",
                                        "number": "李一蓝",
                                        "time": 1702957292,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A2",
                                        "default4": "1",
                                        "default5": "6",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B3",
                                            "name": "张光蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 5,
                                        "id": "B3",
                                        "number": "李一蓝",
                                        "time": 1702957251,
                                        "default1": "A2",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "B3",
                                            "name": "张光蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 6,
                                        "id": "B3",
                                        "number": "李一蓝",
                                        "time": 1702957257,
                                        "default1": "",
                                        "default2": "A3",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "B3",
                                            "name": "张光蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 40,
                                        "dataNumber": 7,
                                        "id": "B3",
                                        "number": "李一蓝",
                                        "time": 1702957277,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A3",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B3",
                                            "name": "张光蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 10,
                                        "dataNumber": 8,
                                        "id": "B3",
                                        "number": "李一蓝",
                                        "time": 1702957292,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A2",
                                        "default4": "1",
                                        "default5": "6",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B3",
                                            "name": "张光蓝"
                                        }
                                    }
                                ]
                            },
                            {
                                "id": "B4",
                                "name": "何亮蓝",
                                "children": [
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 5,
                                        "id": "B4",
                                        "number": "李一蓝",
                                        "time": 1702957251,
                                        "default1": "A4",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "B4",
                                            "name": "何亮蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 6,
                                        "id": "B4",
                                        "number": "李一蓝",
                                        "time": 1702957257,
                                        "default1": "",
                                        "default2": "A4",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "B4",
                                            "name": "何亮蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 40,
                                        "dataNumber": 7,
                                        "id": "B4",
                                        "number": "李一蓝",
                                        "time": 1702957277,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A4",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B4",
                                            "name": "何亮蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 10,
                                        "dataNumber": 8,
                                        "id": "B4",
                                        "number": "李一蓝",
                                        "time": 1702957292,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A4",
                                        "default4": "1",
                                        "default5": "6",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B4",
                                            "name": "何亮蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 5,
                                        "id": "B4",
                                        "number": "李一蓝",
                                        "time": 1702957251,
                                        "default1": "A4",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "B4",
                                            "name": "何亮蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 6,
                                        "id": "B4",
                                        "number": "李一蓝",
                                        "time": 1702957257,
                                        "default1": "",
                                        "default2": "A4",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "B4",
                                            "name": "何亮蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 40,
                                        "dataNumber": 7,
                                        "id": "B4",
                                        "number": "李一蓝",
                                        "time": 1702957277,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A4",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B4",
                                            "name": "何亮蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 10,
                                        "dataNumber": 8,
                                        "id": "B4",
                                        "number": "李一蓝",
                                        "time": 1702957292,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A4",
                                        "default4": "1",
                                        "default5": "6",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B4",
                                            "name": "何亮蓝"
                                        }
                                    }
                                ]
                            },
                            {
                                "id": "B5",
                                "name": "吴天蓝",
                                "children": [
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 5,
                                        "id": "B5",
                                        "number": "李一蓝",
                                        "time": 1702957251,
                                        "default1": "A5",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "B5",
                                            "name": "吴天蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 6,
                                        "id": "B5",
                                        "number": "李一蓝",
                                        "time": 1702957257,
                                        "default1": "",
                                        "default2": "A4",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "B5",
                                            "name": "吴天蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 40,
                                        "dataNumber": 7,
                                        "id": "B5",
                                        "number": "李一蓝",
                                        "time": 1702957277,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A5",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B5",
                                            "name": "吴天蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 10,
                                        "dataNumber": 8,
                                        "id": "B5",
                                        "number": "李一蓝",
                                        "time": 1702957292,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A5",
                                        "default4": "1",
                                        "default5": "6",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B5",
                                            "name": "吴天蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 5,
                                        "id": "B5",
                                        "number": "李一蓝",
                                        "time": 1702957251,
                                        "default1": "A5",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "B5",
                                            "name": "吴天蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 6,
                                        "id": "B5",
                                        "number": "李一蓝",
                                        "time": 1702957257,
                                        "default1": "",
                                        "default2": "A4",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "B5",
                                            "name": "吴天蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 40,
                                        "dataNumber": 7,
                                        "id": "B5",
                                        "number": "李一蓝",
                                        "time": 1702957277,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A5",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B5",
                                            "name": "吴天蓝"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 10,
                                        "dataNumber": 8,
                                        "id": "B5",
                                        "number": "李一蓝",
                                        "time": 1702957292,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "A5",
                                        "default4": "1",
                                        "default5": "6",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "B5",
                                            "name": "吴天蓝"
                                        }
                                    }
                                ]
                            }
                        ],
                        "people": [
                            {
                                "id": "A1",
                                "nameId": "d77edc08-bcfc-4ac0-8d70-f5b6e3d9ca29",
                                "name": "张三红",
                                "result": [
                                    {
                                        "id": "B1",
                                        "name": "王大力蓝",
                                        "children": [
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 0,
                                                "id": "B1",
                                                "number": "王大力蓝",
                                                "time": 1702957202,
                                                "default1": "A1",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "B1",
                                                    "name": "王大力蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 80,
                                                "dataNumber": 2,
                                                "id": "B1",
                                                "number": "王大力蓝",
                                                "time": 1702957227,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A1",
                                                "default4": "1",
                                                "default5": "7",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B1",
                                                    "name": "王大力蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 0,
                                                "dataNumber": 4,
                                                "id": "B1",
                                                "number": "王大力蓝",
                                                "time": 1702957237,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A1",
                                                "default4": "1",
                                                "default5": "9",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B1",
                                                    "name": "王大力蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 0,
                                                "id": "B1",
                                                "number": "王大力蓝",
                                                "time": 1702957202,
                                                "default1": "A1",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "B1",
                                                    "name": "王大力蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 80,
                                                "dataNumber": 2,
                                                "id": "B1",
                                                "number": "王大力蓝",
                                                "time": 1702957227,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A1",
                                                "default4": "1",
                                                "default5": "7",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B1",
                                                    "name": "王大力蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 0,
                                                "dataNumber": 4,
                                                "id": "B1",
                                                "number": "王大力蓝",
                                                "time": 1702957237,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A1",
                                                "default4": "1",
                                                "default5": "9",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B1",
                                                    "name": "王大力蓝"
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        "id": "B2",
                                        "name": "李一蓝",
                                        "children": [
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 6,
                                                "id": "B2",
                                                "number": "李一蓝",
                                                "time": 1702957257,
                                                "default1": "",
                                                "default2": "A1",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "B2",
                                                    "name": "李一蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 10,
                                                "dataNumber": 8,
                                                "id": "B2",
                                                "number": "李一蓝",
                                                "time": 1702957292,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A1",
                                                "default4": "1",
                                                "default5": "6",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B2",
                                                    "name": "李一蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 6,
                                                "id": "B2",
                                                "number": "李一蓝",
                                                "time": 1702957257,
                                                "default1": "",
                                                "default2": "A1",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "B2",
                                                    "name": "李一蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 10,
                                                "dataNumber": 8,
                                                "id": "B2",
                                                "number": "李一蓝",
                                                "time": 1702957292,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A1",
                                                "default4": "1",
                                                "default5": "6",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B2",
                                                    "name": "李一蓝"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "id": "A2",
                                "nameId": "d87edc08-e10d-4550-8455-71749c8cbdd0",
                                "name": "李四红",
                                "result": [
                                    {
                                        "id": "B1",
                                        "name": "王大力蓝",
                                        "children": [
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 1,
                                                "id": "B1",
                                                "number": "王大力蓝",
                                                "time": 1702957211,
                                                "default1": "",
                                                "default2": "A2",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "B1",
                                                    "name": "王大力蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 20,
                                                "dataNumber": 3,
                                                "id": "B1",
                                                "number": "王大力蓝",
                                                "time": 1702957232,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A2",
                                                "default4": "1",
                                                "default5": "9",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B1",
                                                    "name": "王大力蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 1,
                                                "id": "B1",
                                                "number": "王大力蓝",
                                                "time": 1702957211,
                                                "default1": "",
                                                "default2": "A2",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "B1",
                                                    "name": "王大力蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 20,
                                                "dataNumber": 3,
                                                "id": "B1",
                                                "number": "王大力蓝",
                                                "time": 1702957232,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A2",
                                                "default4": "1",
                                                "default5": "9",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B1",
                                                    "name": "王大力蓝"
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        "id": "B2",
                                        "name": "李一蓝",
                                        "children": [
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 5,
                                                "id": "B2",
                                                "number": "李一蓝",
                                                "time": 1702957251,
                                                "default1": "A2",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "B2",
                                                    "name": "李一蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 40,
                                                "dataNumber": 7,
                                                "id": "B2",
                                                "number": "李一蓝",
                                                "time": 1702957277,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A2",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B2",
                                                    "name": "李一蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 0,
                                                "dataNumber": 9,
                                                "id": "B2",
                                                "number": "李一蓝",
                                                "time": 1702957297,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A2",
                                                "default4": "1",
                                                "default5": "9",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B2",
                                                    "name": "李一蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 5,
                                                "id": "B2",
                                                "number": "李一蓝",
                                                "time": 1702957251,
                                                "default1": "A2",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "B2",
                                                    "name": "李一蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 40,
                                                "dataNumber": 7,
                                                "id": "B2",
                                                "number": "李一蓝",
                                                "time": 1702957277,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A2",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B2",
                                                    "name": "李一蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 0,
                                                "dataNumber": 9,
                                                "id": "B2",
                                                "number": "李一蓝",
                                                "time": 1702957297,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A2",
                                                "default4": "1",
                                                "default5": "9",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B2",
                                                    "name": "李一蓝"
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        "id": "B3",
                                        "name": "张光蓝",
                                        "children": [
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 5,
                                                "id": "B3",
                                                "number": "李一蓝",
                                                "time": 1702957251,
                                                "default1": "A2",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "B3",
                                                    "name": "张光蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 10,
                                                "dataNumber": 8,
                                                "id": "B3",
                                                "number": "李一蓝",
                                                "time": 1702957292,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A2",
                                                "default4": "1",
                                                "default5": "6",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B3",
                                                    "name": "张光蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 5,
                                                "id": "B3",
                                                "number": "李一蓝",
                                                "time": 1702957251,
                                                "default1": "A2",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "B3",
                                                    "name": "张光蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 10,
                                                "dataNumber": 8,
                                                "id": "B3",
                                                "number": "李一蓝",
                                                "time": 1702957292,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A2",
                                                "default4": "1",
                                                "default5": "6",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B3",
                                                    "name": "张光蓝"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "id": "A3",
                                "nameId": "d87edc08-9717-4e1d-8c0b-08dbceb2b5c3",
                                "name": "小明红",
                                "result": [
                                    {
                                        "id": "B3",
                                        "name": "张光蓝",
                                        "children": [
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 6,
                                                "id": "B3",
                                                "number": "李一蓝",
                                                "time": 1702957257,
                                                "default1": "",
                                                "default2": "A3",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "B3",
                                                    "name": "张光蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 40,
                                                "dataNumber": 7,
                                                "id": "B3",
                                                "number": "李一蓝",
                                                "time": 1702957277,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A3",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B3",
                                                    "name": "张光蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 6,
                                                "id": "B3",
                                                "number": "李一蓝",
                                                "time": 1702957257,
                                                "default1": "",
                                                "default2": "A3",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "B3",
                                                    "name": "张光蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 40,
                                                "dataNumber": 7,
                                                "id": "B3",
                                                "number": "李一蓝",
                                                "time": 1702957277,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A3",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B3",
                                                    "name": "张光蓝"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "id": "A4",
                                "nameId": "d87edc08-bb23-4848-8610-2b31103b6b08",
                                "name": "王五红",
                                "result": [
                                    {
                                        "id": "B4",
                                        "name": "何亮蓝",
                                        "children": [
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 5,
                                                "id": "B4",
                                                "number": "李一蓝",
                                                "time": 1702957251,
                                                "default1": "A4",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "B4",
                                                    "name": "何亮蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 6,
                                                "id": "B4",
                                                "number": "李一蓝",
                                                "time": 1702957257,
                                                "default1": "",
                                                "default2": "A4",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "B4",
                                                    "name": "何亮蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 40,
                                                "dataNumber": 7,
                                                "id": "B4",
                                                "number": "李一蓝",
                                                "time": 1702957277,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A4",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B4",
                                                    "name": "何亮蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 10,
                                                "dataNumber": 8,
                                                "id": "B4",
                                                "number": "李一蓝",
                                                "time": 1702957292,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A4",
                                                "default4": "1",
                                                "default5": "6",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B4",
                                                    "name": "何亮蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 5,
                                                "id": "B4",
                                                "number": "李一蓝",
                                                "time": 1702957251,
                                                "default1": "A4",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "B4",
                                                    "name": "何亮蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 6,
                                                "id": "B4",
                                                "number": "李一蓝",
                                                "time": 1702957257,
                                                "default1": "",
                                                "default2": "A4",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "B4",
                                                    "name": "何亮蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 40,
                                                "dataNumber": 7,
                                                "id": "B4",
                                                "number": "李一蓝",
                                                "time": 1702957277,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A4",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B4",
                                                    "name": "何亮蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 10,
                                                "dataNumber": 8,
                                                "id": "B4",
                                                "number": "李一蓝",
                                                "time": 1702957292,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A4",
                                                "default4": "1",
                                                "default5": "6",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B4",
                                                    "name": "何亮蓝"
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        "id": "B5",
                                        "name": "吴天蓝",
                                        "children": [
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 6,
                                                "id": "B5",
                                                "number": "李一蓝",
                                                "time": 1702957257,
                                                "default1": "",
                                                "default2": "A4",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "B5",
                                                    "name": "吴天蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 6,
                                                "id": "B5",
                                                "number": "李一蓝",
                                                "time": 1702957257,
                                                "default1": "",
                                                "default2": "A4",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "B5",
                                                    "name": "吴天蓝"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "id": "A5",
                                "nameId": "d87edc08-1a3a-4bf3-8e0e-dcb1bbd6803f",
                                "name": "刘先进红",
                                "result": [
                                    {
                                        "id": "B5",
                                        "name": "吴天蓝",
                                        "children": [
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 5,
                                                "id": "B5",
                                                "number": "李一蓝",
                                                "time": 1702957251,
                                                "default1": "A5",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "B5",
                                                    "name": "吴天蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 40,
                                                "dataNumber": 7,
                                                "id": "B5",
                                                "number": "李一蓝",
                                                "time": 1702957277,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A5",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B5",
                                                    "name": "吴天蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 10,
                                                "dataNumber": 8,
                                                "id": "B5",
                                                "number": "李一蓝",
                                                "time": 1702957292,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A5",
                                                "default4": "1",
                                                "default5": "6",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B5",
                                                    "name": "吴天蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 5,
                                                "id": "B5",
                                                "number": "李一蓝",
                                                "time": 1702957251,
                                                "default1": "A5",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "B5",
                                                    "name": "吴天蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 40,
                                                "dataNumber": 7,
                                                "id": "B5",
                                                "number": "李一蓝",
                                                "time": 1702957277,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A5",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B5",
                                                    "name": "吴天蓝"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 10,
                                                "dataNumber": 8,
                                                "id": "B5",
                                                "number": "李一蓝",
                                                "time": 1702957292,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "A5",
                                                "default4": "1",
                                                "default5": "6",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "B5",
                                                    "name": "吴天蓝"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    "machine": {
                        "name": "蓝队",
                        "teamResult": [
                            {
                                "id": "A1",
                                "name": "张三红",
                                "children": [
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 10,
                                        "id": "A1",
                                        "number": "张光蓝",
                                        "time": 1702957322,
                                        "default1": "B2",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "A1",
                                            "name": "张三红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 11,
                                        "id": "A1",
                                        "number": "张光蓝",
                                        "time": 1702957337,
                                        "default1": "",
                                        "default2": "B1",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "A1",
                                            "name": "张三红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 90,
                                        "dataNumber": 12,
                                        "id": "A1",
                                        "number": "张光蓝",
                                        "time": 1702957377,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B2",
                                        "default4": "1",
                                        "default5": "3",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A1",
                                            "name": "张三红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 60,
                                        "dataNumber": 13,
                                        "id": "A1",
                                        "number": "张光蓝",
                                        "time": 1702957392,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B2",
                                        "default4": "1",
                                        "default5": "3",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A1",
                                            "name": "张三红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 0,
                                        "dataNumber": 14,
                                        "id": "A1",
                                        "number": "张光蓝",
                                        "time": 1702957397,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B2",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A1",
                                            "name": "张三红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 10,
                                        "id": "A1",
                                        "number": "张光蓝",
                                        "time": 1702957322,
                                        "default1": "B2",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "A1",
                                            "name": "张三红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 11,
                                        "id": "A1",
                                        "number": "张光蓝",
                                        "time": 1702957337,
                                        "default1": "",
                                        "default2": "B1",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "A1",
                                            "name": "张三红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 90,
                                        "dataNumber": 12,
                                        "id": "A1",
                                        "number": "张光蓝",
                                        "time": 1702957377,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B2",
                                        "default4": "1",
                                        "default5": "3",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A1",
                                            "name": "张三红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 60,
                                        "dataNumber": 13,
                                        "id": "A1",
                                        "number": "张光蓝",
                                        "time": 1702957392,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B2",
                                        "default4": "1",
                                        "default5": "3",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A1",
                                            "name": "张三红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 0,
                                        "dataNumber": 14,
                                        "id": "A1",
                                        "number": "张光蓝",
                                        "time": 1702957397,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B2",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A1",
                                            "name": "张三红"
                                        }
                                    }
                                ]
                            },
                            {
                                "id": "A2",
                                "name": "李四红",
                                "children": [
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 15,
                                        "id": "A2",
                                        "number": "何亮蓝",
                                        "time": 1702957422,
                                        "default1": "B1",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "A2",
                                            "name": "李四红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 16,
                                        "id": "A2",
                                        "number": "何亮蓝",
                                        "time": 1702957437,
                                        "default1": "",
                                        "default2": "B2",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "A2",
                                            "name": "李四红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 85,
                                        "dataNumber": 17,
                                        "id": "A2",
                                        "number": "何亮蓝",
                                        "time": 1702957457,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B1",
                                        "default4": "1",
                                        "default5": "5",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A2",
                                            "name": "李四红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 70,
                                        "dataNumber": 18,
                                        "id": "A2",
                                        "number": "何亮蓝",
                                        "time": 1702957492,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B1",
                                        "default4": "1",
                                        "default5": "3",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A2",
                                            "name": "李四红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 0,
                                        "dataNumber": 19,
                                        "id": "A2",
                                        "number": "何亮蓝",
                                        "time": 1702957497,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B1",
                                        "default4": "1",
                                        "default5": "9",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A2",
                                            "name": "李四红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 15,
                                        "id": "A2",
                                        "number": "何亮蓝",
                                        "time": 1702957422,
                                        "default1": "B1",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "A2",
                                            "name": "李四红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 16,
                                        "id": "A2",
                                        "number": "何亮蓝",
                                        "time": 1702957437,
                                        "default1": "",
                                        "default2": "B2",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "A2",
                                            "name": "李四红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 85,
                                        "dataNumber": 17,
                                        "id": "A2",
                                        "number": "何亮蓝",
                                        "time": 1702957457,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B1",
                                        "default4": "1",
                                        "default5": "5",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A2",
                                            "name": "李四红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 70,
                                        "dataNumber": 18,
                                        "id": "A2",
                                        "number": "何亮蓝",
                                        "time": 1702957492,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B1",
                                        "default4": "1",
                                        "default5": "3",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A2",
                                            "name": "李四红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 0,
                                        "dataNumber": 19,
                                        "id": "A2",
                                        "number": "何亮蓝",
                                        "time": 1702957497,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B1",
                                        "default4": "1",
                                        "default5": "9",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A2",
                                            "name": "李四红"
                                        }
                                    }
                                ]
                            },
                            {
                                "id": "A3",
                                "name": "小明红",
                                "children": [
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 20,
                                        "id": "A3",
                                        "number": "何亮蓝",
                                        "time": 1702957509,
                                        "default1": "B1",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "A3",
                                            "name": "小明红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 21,
                                        "id": "A3",
                                        "number": "何亮蓝",
                                        "time": 1702957511,
                                        "default1": "",
                                        "default2": "B1",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "A3",
                                            "name": "小明红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 20,
                                        "dataNumber": 22,
                                        "id": "A3",
                                        "number": "何亮蓝",
                                        "time": 1702957517,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B1",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A3",
                                            "name": "小明红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 0,
                                        "dataNumber": 23,
                                        "id": "A3",
                                        "number": "何亮蓝",
                                        "time": 1702957518,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B1",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A3",
                                            "name": "小明红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 20,
                                        "id": "A3",
                                        "number": "何亮蓝",
                                        "time": 1702957509,
                                        "default1": "B1",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "A3",
                                            "name": "小明红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 21,
                                        "id": "A3",
                                        "number": "何亮蓝",
                                        "time": 1702957511,
                                        "default1": "",
                                        "default2": "B1",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "A3",
                                            "name": "小明红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 20,
                                        "dataNumber": 22,
                                        "id": "A3",
                                        "number": "何亮蓝",
                                        "time": 1702957517,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B1",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A3",
                                            "name": "小明红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 0,
                                        "dataNumber": 23,
                                        "id": "A3",
                                        "number": "何亮蓝",
                                        "time": 1702957518,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B1",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A3",
                                            "name": "小明红"
                                        }
                                    }
                                ]
                            },
                            {
                                "id": "A4",
                                "name": "王五红",
                                "children": [
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 20,
                                        "id": "A4",
                                        "number": "何亮蓝",
                                        "time": 1702957509,
                                        "default1": "B4",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "A4",
                                            "name": "王五红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 21,
                                        "id": "A4",
                                        "number": "何亮蓝",
                                        "time": 1702957511,
                                        "default1": "",
                                        "default2": "B4",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "A4",
                                            "name": "王五红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 20,
                                        "dataNumber": 22,
                                        "id": "A4",
                                        "number": "何亮蓝",
                                        "time": 1702957517,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B3",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A4",
                                            "name": "王五红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 0,
                                        "dataNumber": 23,
                                        "id": "A4",
                                        "number": "何亮蓝",
                                        "time": 1702957518,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B3",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A4",
                                            "name": "王五红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 20,
                                        "id": "A4",
                                        "number": "何亮蓝",
                                        "time": 1702957509,
                                        "default1": "B4",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "A4",
                                            "name": "王五红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 21,
                                        "id": "A4",
                                        "number": "何亮蓝",
                                        "time": 1702957511,
                                        "default1": "",
                                        "default2": "B4",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "A4",
                                            "name": "王五红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 20,
                                        "dataNumber": 22,
                                        "id": "A4",
                                        "number": "何亮蓝",
                                        "time": 1702957517,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B3",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A4",
                                            "name": "王五红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 0,
                                        "dataNumber": 23,
                                        "id": "A4",
                                        "number": "何亮蓝",
                                        "time": 1702957518,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B3",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A4",
                                            "name": "王五红"
                                        }
                                    }
                                ]
                            },
                            {
                                "id": "A5",
                                "name": "刘先进红",
                                "children": [
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 20,
                                        "id": "A5",
                                        "number": "何亮蓝",
                                        "time": 1702957509,
                                        "default1": "B4",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "A5",
                                            "name": "刘先进红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 21,
                                        "id": "A5",
                                        "number": "何亮蓝",
                                        "time": 1702957511,
                                        "default1": "",
                                        "default2": "B4",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "A5",
                                            "name": "刘先进红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 20,
                                        "dataNumber": 22,
                                        "id": "A5",
                                        "number": "何亮蓝",
                                        "time": 1702957517,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B5",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A5",
                                            "name": "刘先进红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 0,
                                        "dataNumber": 23,
                                        "id": "A5",
                                        "number": "何亮蓝",
                                        "time": 1702957518,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B4",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A5",
                                            "name": "刘先进红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 20,
                                        "id": "A5",
                                        "number": "何亮蓝",
                                        "time": 1702957509,
                                        "default1": "B4",
                                        "default2": "",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 1,
                                        "enemy": {
                                            "id": "A5",
                                            "name": "刘先进红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 100,
                                        "dataNumber": 21,
                                        "id": "A5",
                                        "number": "何亮蓝",
                                        "time": 1702957511,
                                        "default1": "",
                                        "default2": "B4",
                                        "default3": "",
                                        "default4": "",
                                        "default5": "",
                                        "default6": 2,
                                        "enemy": {
                                            "id": "A5",
                                            "name": "刘先进红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 20,
                                        "dataNumber": 22,
                                        "id": "A5",
                                        "number": "何亮蓝",
                                        "time": 1702957517,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B5",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A5",
                                            "name": "刘先进红"
                                        }
                                    },
                                    {
                                        "addressCode": "1",
                                        "bloodVolume": 0,
                                        "dataNumber": 23,
                                        "id": "A5",
                                        "number": "何亮蓝",
                                        "time": 1702957518,
                                        "default1": "",
                                        "default2": "",
                                        "default3": "B4",
                                        "default4": "1",
                                        "default5": "8",
                                        "default6": 3,
                                        "enemy": {
                                            "id": "A5",
                                            "name": "刘先进红"
                                        }
                                    }
                                ]
                            }
                        ],
                        "people": [
                            {
                                "id": "B1",
                                "nameId": "d87edc08-e15a-44c2-87e3-210955f82503",
                                "name": "王大力蓝",
                                "result": [
                                    {
                                        "id": "A1",
                                        "name": "张三红",
                                        "children": [
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 11,
                                                "id": "A1",
                                                "number": "张光蓝",
                                                "time": 1702957337,
                                                "default1": "",
                                                "default2": "B1",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "A1",
                                                    "name": "张三红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 11,
                                                "id": "A1",
                                                "number": "张光蓝",
                                                "time": 1702957337,
                                                "default1": "",
                                                "default2": "B1",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "A1",
                                                    "name": "张三红"
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        "id": "A2",
                                        "name": "李四红",
                                        "children": [
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 15,
                                                "id": "A2",
                                                "number": "何亮蓝",
                                                "time": 1702957422,
                                                "default1": "B1",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "A2",
                                                    "name": "李四红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 85,
                                                "dataNumber": 17,
                                                "id": "A2",
                                                "number": "何亮蓝",
                                                "time": 1702957457,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B1",
                                                "default4": "1",
                                                "default5": "5",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A2",
                                                    "name": "李四红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 70,
                                                "dataNumber": 18,
                                                "id": "A2",
                                                "number": "何亮蓝",
                                                "time": 1702957492,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B1",
                                                "default4": "1",
                                                "default5": "3",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A2",
                                                    "name": "李四红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 0,
                                                "dataNumber": 19,
                                                "id": "A2",
                                                "number": "何亮蓝",
                                                "time": 1702957497,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B1",
                                                "default4": "1",
                                                "default5": "9",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A2",
                                                    "name": "李四红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 15,
                                                "id": "A2",
                                                "number": "何亮蓝",
                                                "time": 1702957422,
                                                "default1": "B1",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "A2",
                                                    "name": "李四红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 85,
                                                "dataNumber": 17,
                                                "id": "A2",
                                                "number": "何亮蓝",
                                                "time": 1702957457,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B1",
                                                "default4": "1",
                                                "default5": "5",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A2",
                                                    "name": "李四红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 70,
                                                "dataNumber": 18,
                                                "id": "A2",
                                                "number": "何亮蓝",
                                                "time": 1702957492,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B1",
                                                "default4": "1",
                                                "default5": "3",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A2",
                                                    "name": "李四红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 0,
                                                "dataNumber": 19,
                                                "id": "A2",
                                                "number": "何亮蓝",
                                                "time": 1702957497,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B1",
                                                "default4": "1",
                                                "default5": "9",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A2",
                                                    "name": "李四红"
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        "id": "A3",
                                        "name": "小明红",
                                        "children": [
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 20,
                                                "id": "A3",
                                                "number": "何亮蓝",
                                                "time": 1702957509,
                                                "default1": "B1",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "A3",
                                                    "name": "小明红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 21,
                                                "id": "A3",
                                                "number": "何亮蓝",
                                                "time": 1702957511,
                                                "default1": "",
                                                "default2": "B1",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "A3",
                                                    "name": "小明红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 20,
                                                "dataNumber": 22,
                                                "id": "A3",
                                                "number": "何亮蓝",
                                                "time": 1702957517,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B1",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A3",
                                                    "name": "小明红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 0,
                                                "dataNumber": 23,
                                                "id": "A3",
                                                "number": "何亮蓝",
                                                "time": 1702957518,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B1",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A3",
                                                    "name": "小明红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 20,
                                                "id": "A3",
                                                "number": "何亮蓝",
                                                "time": 1702957509,
                                                "default1": "B1",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "A3",
                                                    "name": "小明红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 21,
                                                "id": "A3",
                                                "number": "何亮蓝",
                                                "time": 1702957511,
                                                "default1": "",
                                                "default2": "B1",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "A3",
                                                    "name": "小明红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 20,
                                                "dataNumber": 22,
                                                "id": "A3",
                                                "number": "何亮蓝",
                                                "time": 1702957517,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B1",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A3",
                                                    "name": "小明红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 0,
                                                "dataNumber": 23,
                                                "id": "A3",
                                                "number": "何亮蓝",
                                                "time": 1702957518,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B1",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A3",
                                                    "name": "小明红"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "id": "B2",
                                "nameId": "d87edc08-5466-4941-84c6-7d686f80db78",
                                "name": "李一蓝",
                                "result": [
                                    {
                                        "id": "A1",
                                        "name": "张三红",
                                        "children": [
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 10,
                                                "id": "A1",
                                                "number": "张光蓝",
                                                "time": 1702957322,
                                                "default1": "B2",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "A1",
                                                    "name": "张三红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 90,
                                                "dataNumber": 12,
                                                "id": "A1",
                                                "number": "张光蓝",
                                                "time": 1702957377,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B2",
                                                "default4": "1",
                                                "default5": "3",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A1",
                                                    "name": "张三红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 60,
                                                "dataNumber": 13,
                                                "id": "A1",
                                                "number": "张光蓝",
                                                "time": 1702957392,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B2",
                                                "default4": "1",
                                                "default5": "3",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A1",
                                                    "name": "张三红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 0,
                                                "dataNumber": 14,
                                                "id": "A1",
                                                "number": "张光蓝",
                                                "time": 1702957397,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B2",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A1",
                                                    "name": "张三红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 10,
                                                "id": "A1",
                                                "number": "张光蓝",
                                                "time": 1702957322,
                                                "default1": "B2",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "A1",
                                                    "name": "张三红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 90,
                                                "dataNumber": 12,
                                                "id": "A1",
                                                "number": "张光蓝",
                                                "time": 1702957377,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B2",
                                                "default4": "1",
                                                "default5": "3",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A1",
                                                    "name": "张三红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 60,
                                                "dataNumber": 13,
                                                "id": "A1",
                                                "number": "张光蓝",
                                                "time": 1702957392,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B2",
                                                "default4": "1",
                                                "default5": "3",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A1",
                                                    "name": "张三红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 0,
                                                "dataNumber": 14,
                                                "id": "A1",
                                                "number": "张光蓝",
                                                "time": 1702957397,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B2",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A1",
                                                    "name": "张三红"
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        "id": "A2",
                                        "name": "李四红",
                                        "children": [
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 16,
                                                "id": "A2",
                                                "number": "何亮蓝",
                                                "time": 1702957437,
                                                "default1": "",
                                                "default2": "B2",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "A2",
                                                    "name": "李四红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 16,
                                                "id": "A2",
                                                "number": "何亮蓝",
                                                "time": 1702957437,
                                                "default1": "",
                                                "default2": "B2",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "A2",
                                                    "name": "李四红"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "id": "B3",
                                "nameId": "d87edc08-2f6e-4ca6-89b1-f4ac3e85dac3",
                                "name": "张光蓝",
                                "result": [
                                    {
                                        "id": "A4",
                                        "name": "王五红",
                                        "children": [
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 20,
                                                "dataNumber": 22,
                                                "id": "A4",
                                                "number": "何亮蓝",
                                                "time": 1702957517,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B3",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A4",
                                                    "name": "王五红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 0,
                                                "dataNumber": 23,
                                                "id": "A4",
                                                "number": "何亮蓝",
                                                "time": 1702957518,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B3",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A4",
                                                    "name": "王五红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 20,
                                                "dataNumber": 22,
                                                "id": "A4",
                                                "number": "何亮蓝",
                                                "time": 1702957517,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B3",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A4",
                                                    "name": "王五红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 0,
                                                "dataNumber": 23,
                                                "id": "A4",
                                                "number": "何亮蓝",
                                                "time": 1702957518,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B3",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A4",
                                                    "name": "王五红"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "id": "B4",
                                "nameId": "d87edc08-ac75-4924-843d-0a2343f2751c",
                                "name": "何亮蓝",
                                "result": [
                                    {
                                        "id": "A4",
                                        "name": "王五红",
                                        "children": [
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 20,
                                                "id": "A4",
                                                "number": "何亮蓝",
                                                "time": 1702957509,
                                                "default1": "B4",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "A4",
                                                    "name": "王五红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 21,
                                                "id": "A4",
                                                "number": "何亮蓝",
                                                "time": 1702957511,
                                                "default1": "",
                                                "default2": "B4",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "A4",
                                                    "name": "王五红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 20,
                                                "id": "A4",
                                                "number": "何亮蓝",
                                                "time": 1702957509,
                                                "default1": "B4",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "A4",
                                                    "name": "王五红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 21,
                                                "id": "A4",
                                                "number": "何亮蓝",
                                                "time": 1702957511,
                                                "default1": "",
                                                "default2": "B4",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "A4",
                                                    "name": "王五红"
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        "id": "A5",
                                        "name": "刘先进红",
                                        "children": [
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 20,
                                                "id": "A5",
                                                "number": "何亮蓝",
                                                "time": 1702957509,
                                                "default1": "B4",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "A5",
                                                    "name": "刘先进红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 21,
                                                "id": "A5",
                                                "number": "何亮蓝",
                                                "time": 1702957511,
                                                "default1": "",
                                                "default2": "B4",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "A5",
                                                    "name": "刘先进红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 0,
                                                "dataNumber": 23,
                                                "id": "A5",
                                                "number": "何亮蓝",
                                                "time": 1702957518,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B4",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A5",
                                                    "name": "刘先进红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 20,
                                                "id": "A5",
                                                "number": "何亮蓝",
                                                "time": 1702957509,
                                                "default1": "B4",
                                                "default2": "",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 1,
                                                "enemy": {
                                                    "id": "A5",
                                                    "name": "刘先进红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 100,
                                                "dataNumber": 21,
                                                "id": "A5",
                                                "number": "何亮蓝",
                                                "time": 1702957511,
                                                "default1": "",
                                                "default2": "B4",
                                                "default3": "",
                                                "default4": "",
                                                "default5": "",
                                                "default6": 2,
                                                "enemy": {
                                                    "id": "A5",
                                                    "name": "刘先进红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 0,
                                                "dataNumber": 23,
                                                "id": "A5",
                                                "number": "何亮蓝",
                                                "time": 1702957518,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B4",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A5",
                                                    "name": "刘先进红"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "id": "B5",
                                "nameId": "d87edc08-7a82-4e44-8994-4fbc79fbe037",
                                "name": "吴天蓝",
                                "result": [
                                    {
                                        "id": "A5",
                                        "name": "刘先进红",
                                        "children": [
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 20,
                                                "dataNumber": 22,
                                                "id": "A5",
                                                "number": "何亮蓝",
                                                "time": 1702957517,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B5",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A5",
                                                    "name": "刘先进红"
                                                }
                                            },
                                            {
                                                "addressCode": "1",
                                                "bloodVolume": 20,
                                                "dataNumber": 22,
                                                "id": "A5",
                                                "number": "何亮蓝",
                                                "time": 1702957517,
                                                "default1": "",
                                                "default2": "",
                                                "default3": "B5",
                                                "default4": "1",
                                                "default5": "8",
                                                "default6": 3,
                                                "enemy": {
                                                    "id": "A5",
                                                    "name": "刘先进红"
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    "description": "课目八训练"
                }
            },
            // 课目九
            {
                "name": "课目九训练",
                "data": {
                    "name": "课目九训练",
                    "class": {
                        "name": "一班",
                        "children": [
                            
                        ],
                        "id": "d77edc08-f5a4-4b6d-8f9f-8910ead6fcf7"
                    },
                    "site": {
                        "number": "5",
                        "name": "训练场5"
                    },
                    "tran": {
                        "type": "模式三",
                        "key": "9",
                        "value": "编队5V5对抗模拟射击指挥训练",
                        "children": [
                            
                        ],
                        "id": "dd7edc08-8e0a-4352-89b7-ce2409879c1f"
                    },
                    "timeRange": [
                        "2024-05-20 00:00:00",
                        "2024-05-20 10:59:59"
                    ],
                    "peopleTeam": {
                        "name": "红队",
                        "teamResult": [
                            
                        ],
                        "people": [
                            {
                                "id": "A1",
                                "nameId": "d77edc08-bcfc-4ac0-8d70-f5b6e3d9ca29",
                                "name": "张三红",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "A2",
                                "nameId": "d87edc08-e10d-4550-8455-71749c8cbdd0",
                                "name": "李四红",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "A3",
                                "nameId": "d87edc08-9717-4e1d-8c0b-08dbceb2b5c3",
                                "name": "小明红",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "A4",
                                "nameId": "d87edc08-bb23-4848-8610-2b31103b6b08",
                                "name": "王五红",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "A5",
                                "nameId": "d87edc08-1a3a-4bf3-8e0e-dcb1bbd6803f",
                                "name": "刘先进红",
                                "result": [
                                    
                                ]
                            }
                        ]
                    },
                    "machine": {
                        "name": "蓝队",
                        "teamResult": [
                            
                        ],
                        "people": [
                            {
                                "id": "B1",
                                "nameId": "d87edc08-e15a-44c2-87e3-210955f82503",
                                "name": "王大力蓝",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "B2",
                                "nameId": "d87edc08-5466-4941-84c6-7d686f80db78",
                                "name": "李一蓝",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "B3",
                                "nameId": "d87edc08-2f6e-4ca6-89b1-f4ac3e85dac3",
                                "name": "张光蓝",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "B4",
                                "nameId": "d87edc08-ac75-4924-843d-0a2343f2751c",
                                "name": "何亮蓝",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "B5",
                                "nameId": "d87edc08-7a82-4e44-8994-4fbc79fbe037",
                                "name": "吴天蓝",
                                "result": [
                                    
                                ]
                            }
                        ]
                    },
                    "description": "课目九训练"
                }
            },
            //课目十 
            {
                "name": "课目十训练",
                "data": {
                    "name": "课目十训练",
                    "class": {
                        "name": "一班",
                        "children": [
                            
                        ],
                        "id": "d77edc08-f5a4-4b6d-8f9f-8910ead6fcf7"
                    },
                    "site": {
                        "number": "4",
                        "name": "训练场4"
                    },
                    "tran": {
                        "type": "模式三",
                        "key": "10",
                        "value": "编队7V7对抗模拟射击",
                        "children": [
                            
                        ],
                        "id": "dd7edc08-f51c-4ab8-8ccc-d0d902bd9145"
                    },
                    "timeRange": [
                        "2024-05-22 00:00:00",
                        "2024-05-23 23:59:59"
                    ],
                    "peopleTeam": {
                        "name": "红队",
                        "teamResult": [
                            
                        ],
                        "people": [
                            {
                                "id": "A1",
                                "nameId": "d77edc08-bcfc-4ac0-8d70-f5b6e3d9ca29",
                                "name": "张三红",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "A2",
                                "nameId": "d87edc08-e10d-4550-8455-71749c8cbdd0",
                                "name": "李四红",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "A3",
                                "nameId": "d87edc08-9717-4e1d-8c0b-08dbceb2b5c3",
                                "name": "小明红",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "A4",
                                "nameId": "d87edc08-bb23-4848-8610-2b31103b6b08",
                                "name": "王五红",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "A5",
                                "nameId": "d87edc08-1a3a-4bf3-8e0e-dcb1bbd6803f",
                                "name": "刘先进红",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "A6",
                                "nameId": "d87edc08-1d45-4afc-833d-08d65c9a4cbe",
                                "name": "仗义红",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "A7",
                                "nameId": "d87edc08-a750-479b-82cd-1098f27761b0",
                                "name": "李红",
                                "result": [
                                    
                                ]
                            }
                        ]
                    },
                    "machine": {
                        "name": "蓝队",
                        "teamResult": [
                            
                        ],
                        "people": [
                            {
                                "id": "B1",
                                "nameId": "d87edc08-e15a-44c2-87e3-210955f82503",
                                "name": "王大力蓝",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "B2",
                                "nameId": "d87edc08-5466-4941-84c6-7d686f80db78",
                                "name": "李一蓝",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "B3",
                                "nameId": "d87edc08-2f6e-4ca6-89b1-f4ac3e85dac3",
                                "name": "张光蓝",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "B4",
                                "nameId": "d87edc08-ac75-4924-843d-0a2343f2751c",
                                "name": "何亮蓝",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "B5",
                                "nameId": "d87edc08-7a82-4e44-8994-4fbc79fbe037",
                                "name": "吴天蓝",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "B6",
                                "nameId": "d87edc08-528b-4210-8ef5-609de4120510",
                                "name": "王天蓝",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "B7",
                                "nameId": "d87edc08-a993-4c8d-87cd-66d039c5b6db",
                                "name": "李峰蓝",
                                "result": [
                                    
                                ]
                            }
                        ]
                    },
                    "description": "课目十训练"
                }
            },
            // 课目十一
            {
                "name": "课目十一训练",
                "data": {
                    "name": "课目十一训练",
                    "class": {
                        "name": "一班",
                        "children": [
                            
                        ],
                        "id": "d77edc08-f5a4-4b6d-8f9f-8910ead6fcf7"
                    },
                    "site": {
                        "number": "4",
                        "name": "训练场4"
                    },
                    "tran": {
                        "type": "模式三",
                        "key": "11",
                        "value": "编队7V7对抗模拟射击指挥训练",
                        "children": [
                            
                        ],
                        "id": "d185dc08-32e9-4970-861b-7045b4f55573"
                    },
                    "timeRange": [
                        "2024-05-25 00:00:00",
                        "2024-05-26 23:59:59"
                    ],
                    "peopleTeam": {
                        "name": "红队",
                        "teamResult": [
                            
                        ],
                        "people": [
                            {
                                "id": "A1",
                                "nameId": "d77edc08-bcfc-4ac0-8d70-f5b6e3d9ca29",
                                "name": "张三红",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "A2",
                                "nameId": "d87edc08-e10d-4550-8455-71749c8cbdd0",
                                "name": "李四红",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "A3",
                                "nameId": "d87edc08-9717-4e1d-8c0b-08dbceb2b5c3",
                                "name": "小明红",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "A4",
                                "nameId": "d87edc08-bb23-4848-8610-2b31103b6b08",
                                "name": "王五红",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "A5",
                                "nameId": "d87edc08-1a3a-4bf3-8e0e-dcb1bbd6803f",
                                "name": "刘先进红",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "A6",
                                "nameId": "d87edc08-1d45-4afc-833d-08d65c9a4cbe",
                                "name": "仗义红",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "A7",
                                "nameId": "d87edc08-a750-479b-82cd-1098f27761b0",
                                "name": "李红",
                                "result": [
                                    
                                ]
                            }
                        ]
                    },
                    "machine": {
                        "name": "蓝队",
                        "teamResult": [
                            
                        ],
                        "people": [
                            {
                                "id": "B1",
                                "nameId": "d87edc08-e15a-44c2-87e3-210955f82503",
                                "name": "王大力蓝",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "B2",
                                "nameId": "d87edc08-5466-4941-84c6-7d686f80db78",
                                "name": "李一蓝",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "B3",
                                "nameId": "d87edc08-2f6e-4ca6-89b1-f4ac3e85dac3",
                                "name": "张光蓝",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "B4",
                                "nameId": "d87edc08-ac75-4924-843d-0a2343f2751c",
                                "name": "何亮蓝",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "B5",
                                "nameId": "d87edc08-7a82-4e44-8994-4fbc79fbe037",
                                "name": "吴天蓝",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "B6",
                                "nameId": "d87edc08-528b-4210-8ef5-609de4120510",
                                "name": "王天蓝",
                                "result": [
                                    
                                ]
                            },
                            {
                                "id": "B7",
                                "nameId": "d87edc08-a993-4c8d-87cd-66d039c5b6db",
                                "name": "李峰蓝",
                                "result": [
                                    
                                ]
                            }
                        ]
                    },
                    "description": "课目十一训练"
                }
            }
        ],
    },
    mutations:{
        EDIT_TRAIN_DATA(state,data){
            state.trainData = data
        }
    }
}
  
  export default testTrainData