import router from './router'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css'// Progress 进度条样式
import { getToken } from '@/utils/auth' // 验权

const whiteList = ['/login'] // 不重定向白名单
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (getToken()) {
    if (to.path === '/login') { // 登录后login自动跳转
      next({ path: '/' })
      return
    }
    next()
    return
  }
  // 一些页面不用登录也可以访问
  if (whiteList.indexOf(to.path) !== -1) { // 没登录情况下过滤白名单
    next()
  } else {
    next('/login')
  }
})

router.afterEach((to, from) => {
  if (to.fullPath != "/login") {
    window.sessionStorage.prevUrl = to.fullPath
  }
  NProgress.done() // 结束Progress
})
