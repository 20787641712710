<template>
  <div class="login-container">
    <div class="content">
      <!-- <img class="leftImg" src="~@/assets/login/left.png" alt=""> -->
      <el-form class="login-form" :model="loginForm" :rules="loginRules" ref="loginForm"
        label-position="left">
        <h3 class="title">一体化智能战术对抗演训综合训练评估系统</h3>
        <p class="tips"></p>
        <el-form-item prop="Account">
          <el-input
            placeholder="请输入登录账号"
            v-model="loginForm.Account"
            prefix-icon="el-icon-user"
            @keyup.enter.native="handleLogin"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item prop="Password">
          <el-input 
            placeholder="请输入密码"
            type="password"
            v-model="loginForm.Password" 
            @keyup.enter.native="handleLogin"
            show-Password prefix-icon="el-icon-lock">
          </el-input>
        </el-form-item>
        <el-form-item style="background: none">
          <el-button  style="width:100%;background:rgb(32 74 100);font-size: 24px;height: 50px;box-shadow: 0px 0px 16px rgb(255 255 255);"
            :loading="loading" @click.native.prevent="handleLogin">
            登 录
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import user from "@/request/user"
  import { setToken,X_setToken } from '@/utils/auth'
  export default {
      data() {
        const validateAccount = (rule, value, callback) => {
          if (value.length <= 0) {
            callback(new Error('登录账号不能为空'))
          } else {
            callback()
          }
        }
        const validatePass = (rule, value, callback) => {
          if (value.length <= 0) {
            callback(new Error('密码不能为空'))
          } else {
            callback()
          }
        }
        return {
          loginForm: {
            Account: 'admin',
            Password: 'admin'
          },
          loginRules: {
            Account: [{
              required: true,
              trigger: 'blur',
              validator: validateAccount
            }],
            Password: [{
              required: true,
              trigger: 'blur',
              validator: validatePass
            }]
          },
          loading:false
        }
      },
      methods: {
        handleLogin() {
          this.$refs.loginForm.validate(async valid => {
            if (valid) {
              const res = await user.login(this.loginForm)
              // console.log(res);
              if(res.data.statusCode !== 200){
                this.$message.error(res.data.errors)
                return
              }
              setToken(res.headers["access-token"]) //存token  你的token
              X_setToken(res.headers['x-access-token'])   //你的刷新token
              this.$message.success("登录成功")
              // this.$router.push({path: '/'})
              const userRes = await user.loginInfo()
              this.$store.commit("edit_userInfo",userRes.data)
              // console.log(window.sessionStorage.prevUrl);
              if(window.sessionStorage.prevUrl){
                this.$router.push({path: window.sessionStorage.prevUrl})
              }else{
                this.$router.push({path: '/'})
              }
              // 处理路由
            } else {
              console.log('error submit!!')
              return false
            }
          })
        }
      }
  }
</script>

<style scoped lang="scss">
  .login-container {
    width: 100%;
    height: 100%;
    text-align: center;
    &::before{
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
    .content{
      display: inline-block;
      vertical-align: middle;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 5px;
      padding: 20px;
    }
    .login-form{
      display: inline-block;
      width: 400px;
      vertical-align: middle;
      .title{
        font-size:24px;
        font-weight: 700;
        color: #23516d;
        margin-bottom:40px;
      }
      .el-form-item{
        margin-bottom:35px;
        ::v-deep .el-form-item__content{
          background-color: rgba(223, 223, 223, 0.5);
          line-height: 47px;
          border: 1px solid #d3d8d4;
          border-radius: 5px;
          .el-input__icon{
            color:#333;
            font-size:16px;
          }
        }
        ::v-deep .el-input__inner{
          background-color: transparent;
          border: 0px;
          border-radius: 0px;
          color: #333;
          height: 47px;
          font-weight: bold;
          &::placeholder {
            color: #606266;
          }
        }
      }
    }
    .el-button{
      color: #fff;
      border: none;
    }
  }
</style>