<template>
  <div class="navbar">
    <div class="navbar_title">
        一体化智能战术对抗演训综合训练评估系统
    </div>
    <div class="navbar_dropdown">
        <el-dropdown class="avatar-container" trigger="hover" @command="handleCommand">
            <div class="avatar-wrapper">
                欢迎您，{{geName(userInfo)|| userInfo.account}}
                <i class="el-icon-caret-bottom"></i>
            </div>
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
                <el-dropdown-item command="editPwd">
                    <span>修改密码</span>
                </el-dropdown-item>
                <el-dropdown-item command="logout">
                    <span>退出登录</span>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
    <!-- 修改信息 -->
    <el-dialog
      top="10vh"
      title="修改密码"
      :visible.sync="dialogUserVisible"
      width="500px">
      <div class="dialog_form">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
          <el-form-item label="新密码：" prop="password" style="width:400px;">
              <el-input v-model="ruleForm.password" clearable></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="sureEditUser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import user from "@/request/user"
export default {
    data(){
        return{
            userInfo:this.$store.state.userInfo,
            // 修改密码
            dialogUserVisible:false,
            ruleForm:{
                password:"",
            },
            rules:{
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
            },
        }
    },
    methods:{
        handleCommand(name) {
            if(!name) return
            this[name]()
        },
        logout(){
            localStorage.clear('x-token')
            localStorage.clear('token')
            sessionStorage.clear()
            this.$store.commit('DEL_ALL_VIEWS')
            this.$router.push("/login")
        },
        geName(obj){
            let nameStr = obj.userProfileList.find(item => item.key === 'name')
            return nameStr && nameStr.value
        },
        // 修改密码
        editPwd(){
            this.dialogUserVisible = true
        },
        sureEditUser(){
            this.$refs.ruleForm.validate(async (valid) => {
                if(valid){
                    const res = await user.editPwd({userId:this.userInfo.id,password:this.ruleForm.password})
                    if(res.statusCode != 200){
                        this.$message.error(res.errors)
                        return
                    }
                    this.$message.success("修改成功")
                    this.handleClose()
                }
               
            })
        },
        handleClose(){
            this.dialogUserVisible = false
            this.ruleForm.password = ""
        },
    }
}
</script>

<style lang="scss" scoped>
.navbar{
    width: 100%;
    height:100%;
    background: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 20px;
    .navbar_title{
        font-size:20px;
    }
    .navbar_dropdown{
        .avatar-wrapper{
            cursor: pointer;
        }
        
    }
}
</style>