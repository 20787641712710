<template>
  <div class="tags-view-container">
    <scroll-pane class='tags-view-wrapper' ref='scrollPane'>
      <router-link ref='tag' class="tags-view-item" :class="isActive(tag)?'active':''" v-for="tag in Array.from(visitedViews)"
        :to="tag" :key="tag.path" @contextmenu.prevent.native="openMenu(tag,$event)">
        {{tag.title}}
        <span class='el-icon-close' @click.prevent.stop='closeSelectedTag(tag)'></span>
      </router-link>
    </scroll-pane>
    <ul class='contextmenu' v-show="visible" :style="{left:left+'px',top:top+'px'}">
      <li @click="closeSelectedTag(selectedTag)">关闭</li>
      <li @click="closeOthersTags">关闭其他</li>
      <li @click="closeAllTags">全部关闭</li>
    </ul>
  </div>
</template>

<script>
import ScrollPane from '@/components/ScrollPane'
import { mapGetters } from 'vuex'
export default {
  components: { ScrollPane },
  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {}
    }
  },
  computed: {
    ...mapGetters(['visitedViews']),
    // visitedViews() {
    //   return this.$store.state.tagsView.visitedViews
    // }
  },
  watch: {
    $route() {
      this.addViewTags()
      this.moveToCurrentTag()
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    }
  },
  mounted() {
    this.addViewTags()
  },
  methods: {
    // 获取当前的路由参数
    generateRoute() {
      if (this.$route.name) {
        return this.$route
      }
      return false
    },
    isActive(route) {
      return route.path === this.$route.path
    },
    // 添加路由信息到tab栏
    addViewTags() {
      const route = this.generateRoute()
      if (!route) {
        return false
      }
      this.$store.dispatch('addVisitedViews', route)
    },
    moveToCurrentTag() {
      const tags = this.$refs.tag
      this.$nextTick(() => {
        for (const tag of tags) {
          if (tag.to.path === this.$route.path) {
            this.$refs.scrollPane.moveToTarget(tag.$el)
            break
          }
        }
      })
    },
    // 关闭
    closeSelectedTag(view) {
      if(view.path === "/home" && this.visitedViews.length === 1){
        this.$message.warning("已经是首页")
        return
      }
      
      this.$store.dispatch('delVisitedViews', view).then(views => {
        if (this.isActive(view)) {
          const latestView = views.slice(-1)[0]
          if (latestView) {
            this.$router.push(latestView)
          } else {
            this.$router.push('/')
          }
        }
      })
    },
    // 关闭其它
    closeOthersTags() {
      if(this.selectedTag.path != this.$route.path){
        this.$router.push(this.selectedTag)
      }
      this.$store.dispatch('delOthersViews', this.selectedTag).then(() => {
        this.moveToCurrentTag()
      })
    },
    // 全部关闭
    closeAllTags() {
      this.$store.dispatch('delAllViews')
      this.$router.push('/')
    },
    // 鼠标右键打开菜单
    openMenu(tag, e) {
      this.visible = true
      this.selectedTag = tag
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      this.left = e.clientX - offsetLeft + 15 + 260 // 15: margin right
      this.top = e.clientY
    },
    // 关闭右键菜单
    closeMenu() {
      this.visible = false
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.custom-theme{
  .tags-view-container {
    .tags-view-wrapper {
      .tags-view-item {
        &.active {
            color: #fff;
            background-color: #99a24c;
            border-color: #dcdfe6;
            &::before {
                background: #fff;
            }
        }
      }
    }
  }

}
.tags-view-container {
    .tags-view-wrapper {
        height: 40px;
        border-bottom: 1px solid #d8dce5;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
        .tags-view-item {
            display: inline-block;
            position: relative;
            height: 40px;
            line-height: 40px;
            border-right: 1px solid #d8dce5;
            color: #fff;
            
            padding: 0 8px;
            margin-left: 5px;
            // margin-top: 4px;
            &.active {
              color: #fff;
              background-color: #409eff;
              // border-color: #409eff;
              &::before {
                  content: '';
                  background: #fff;
                  display: inline-block;
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  position: relative;
                  margin-right: 2px;
              }
            }
        }
    }
    .contextmenu {
        margin: 0;
        // background: #fff;
        z-index: 10000;
        position: absolute;
        list-style-type: none;
        padding: 5px 0;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #000;
        box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
        background: rgb(255,255,255);
        li {
            margin: 0;
            padding: 7px 16px;
            cursor: pointer;
            color:#222;
            &:hover {
                background: #eee;
            }
        }
    }
}
</style>

<style rel="stylesheet/scss" lang="scss">
.tags-view-wrapper {
    .tags-view-item {
        .el-icon-close {
            vertical-align: 0px;
            border-radius: 50%;
            text-align: center;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transform-origin: 100% 50%;
            &:before {
                transform: scale(0.6);
                display: inline-block;
                vertical-align: 0px;
            }
            &:hover {
              display: inline-block;
              // vertical-align: -2px;
              background-color: #b4bccc;
              color: #fff;
            }
        }
    }
}
</style>
