<template>
  <div class="home">
		<el-container>
			<el-header height="45px">
				<NavBar></NavBar>
			</el-header>
			<el-container class="mainContent">
				<SideBar class="main_sidebar"></SideBar>
				<div class="mainShowContent">
					<tags-view class="custom-tags-view"></tags-view>
					<div class="showContent" v-show="key === '/home'">
            <div class="home_box show_home">
              <div class="home_box_li">
                <h1>一体化智能战术对抗演训综合训练评估系统</h1>
                <img src="@/assets/home/badge.png" alt="徽章" />
              </div>
              <div class="home_box_li">
                <ul class="show_home_ul">
                  <li @click="goUrl('/Service')">
                    <img src="@/assets/home/home1.png" alt="">
                    <p>训练计划安排</p>
                  </li>
                  <li @click="goUrl('/Service/inquire')">
                    <img src="@/assets/home/home2.png" alt="">
                    <p>训练日程表</p>
                  </li>
                  <li @click="goUrl('/Analysis')">
                    <img src="@/assets/home/home3.png" alt="">
                    <p>个人训练效果曲线</p>
                  </li>
                  <li @click="goUrl('/Class')">
                    <img src="@/assets/home/home4.png" alt="">
                    <p>班级训练效果对比</p>
                  </li>
                </ul>
              </div>
              <div class="home_box_li table_flex_column">
                <div class="table_con">
                  <el-table
                    ref="multipleTable"
                    :data="tranList"
                    tooltip-effect="dark"
                    style="width: 100%;background-color:rgba(255,255,255,0.1)"
                    height="100%"
                    @selection-change="handleSelectionChange">
                    <el-table-column
                      type="selection"
                      width="50"
                      :align="'center'">
                    </el-table-column>
                    <el-table-column
                      label="名称">
                      <template slot-scope="scope">{{ scope.row.name }}</template>
                    </el-table-column>
                    <el-table-column
                      label="班级">
                      <template slot-scope="scope">{{ scope.row.className }}</template>
                    </el-table-column>
                    <el-table-column
                      label="训练课目" width="240">
                      <template slot-scope="scope">
                        <span>{{ scope.row.tranName }}</span>
                        <span class="bot_sub">{{scope.row.tranKey}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      width="120"
                      label="开始时间">
                      <template slot-scope="scope">{{ scope.row.startTime }}</template>
                    </el-table-column>
                    <el-table-column
                      width="120"
                      label="结束时间">
                      <template slot-scope="scope">{{ scope.row.endTime }}</template>
                    </el-table-column>
                  </el-table>
                </div>
                
              </div>
              <div class="home_box_li">
                <div ref="echarts" style="width:96%;height:100%;margin:0 auto;"></div>
              </div>
            </div>
          </div>
          <div class="showContent" v-show="key !== '/home'">
            <keep-alive>
              <router-view :key="key" v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
              <router-view :key="key" v-if="!$route.meta.keepAlive"></router-view>
          </div>
				</div>
			</el-container>
		</el-container>
	</div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import TagsView from './components/TagsView.vue'
import SideBar from './components/SideBar.vue'

import * as echarts from 'echarts'

import user from "@/request/user"
import dic from "@/request/dic"
import training from "@/request/training"
import { setToken,X_setToken } from '@/utils/auth'

export default {
  components: {
    TagsView,
    NavBar,
    SideBar
  },
  data(){
    return{
      trainingData:[],
      tranList:[],
      txtobj:[],
      numberArr:[],
      myChart:null,
    }
  },
  computed:{
    key() {
      return this.$route.fullPath
    }
  },
  watch:{
    // 监听路由，防止柱状图可视化的展示变形
    key(newVal){
      if(newVal === "/home"){
        setTimeout(()=>{
          this.myChart.resize()
        },100)
      }
    }
  },
  created(){
    // 定时调用接口，防止超时，演示的时候使用
    setInterval(async ()=>{
      const res = await user.login({
        Account: 'admin',
        Password: 'admin'
      })
      if(res.data.statusCode === 200){
        setToken(res.headers["access-token"]) //存token  你的token
        X_setToken(res.headers['x-access-token']) 
        return
      }
    },1000 * 30)
    this.getTrainMode()
  },
  mounted(){
    this.initEchart()
    this.getTrainData()
  },
  methods: {
    goUrl(url){
      this.$router.push(url)
    },
    // 训练模式
    async getTrainMode(){
      const res = await dic.dicList({type:"trainMode",Paged:false})
      if(res.statusCode === 200){
          this.trainingData = res.data.items.map(item => {
            let tranVal = JSON.parse(item.value)
            return{
                ...item,
                key:tranVal.key,
                name:tranVal.name,
                modeName:tranVal.type,
            }
          }).sort((a,b)=>a.key-b.key)
      }
    },
    // 训练计划
    async getTrainData(){
      const res = await training.trainingDataList({Paged:false})
      if(res.statusCode !== 200){
        this.$message.error(res.errors)
        return
      }
      let mapArr = res.data.items.map(item => {
        let tranName = this.trainingData.find(tItem => tItem.id === item.trainingPlant.project)
        let planData = JSON.parse(item.data)
        return{
          id:item.trainingPlant.id,
          name:item.trainingPlant.name,
          classId:item.trainingPlant.organization.id || "",
          className:item.trainingPlant.organization.name || "",
          tranName:tranName?tranName.name:"",
          tranKey:tranName?tranName.key:"",
          tranId:tranName?tranName.id:"",
          description:item.trainingPlant.description,
          startTime:this.$utils.formatDate(+new Date(item.trainingPlant.startTime), 'yyyy-MM-DD HH:mm:ss'),
          endTime:this.$utils.formatDate(+new Date(item.trainingPlant.endTime), 'yyyy-MM-DD HH:mm:ss'),
          extraData:planData.extraData
        }
      })
      this.tranList = mapArr.filter(item=>item.extraData)
      if(this.tranList.length > 5){
        this.tranList = this.tranList.slice(0,5)
      }
      this.tranList.forEach(item=>{
        this.dealSearch(item)
      })
    },
    // 复选框勾选
    handleSelectionChange(val) {
      this.txtobj = []
      this.numberArr = []
      this.multipleSelection = val
      val.forEach(item => {
        this.dealSearch(item)
      })
      if(val.length === 0){
        this.initEchart()
      }
    },
    // 处理勾选的数据
    dealSearch(val){
      let findObj = val
      let newMapArr = val.extraData.data.map(item=>{
        return {
          ...item,
          tranName:findObj.tranName,
          startTime:findObj.startTime,
          endTime:findObj.endTime,
          typeKey:findObj.tranKey,
          className:findObj.className,
          planName:findObj.name
        }
      })
      let newArr = []
      newArr.push({
        tranName:findObj.tranName,
        typeKey:findObj.tranKey,
        className:findObj.className,
        planName:findObj.name,
        startTime:findObj.startTime,
        endTime:findObj.endTime,
      })
      this.txtobj = [...this.txtobj,...newArr]

      let scoreArr = [0,0,0,0,0]
      newMapArr.forEach(item=>{
        if(+item.evaluation.score > 89){
          scoreArr[4]++
        }else if(+item.evaluation.score > 79){
          scoreArr[3]++
        }else if(+item.evaluation.score > 69){
          scoreArr[2]++
        }else if(+item.evaluation.score > 59){
          scoreArr[1]++
        }else{
          scoreArr[0]++
        }
      })
      this.numberArr.push(scoreArr)
      this.initEchart()
    },
    // 可视化
    initEchart(arr=[0,5,6,5,2]){
      let numberArr = ['60分以下','60~69','70~79','80~89','90~100']

      let option = {
          grid: {
              x: 30,
              y: 50,
              x2: 30,
              y2: 20
          },
          // legend: {
          //   data: "训练成绩",
          //   textStyle:{
          //     color:'#fff'
          //   }
          // },
          title:{
            text:"班次训练成绩对比图",
            textStyle:{
              color:"#fff"
            }
          },
          tooltip: {
              trigger: "axis",
          },
          xAxis: {
              type: 'category',
              data: numberArr,
              axisLabel:{
                  textStyle:{
                      color:'#fff'
                  }
              }
          },
          yAxis: {
              type: 'value',
              min:0,
              // max:100,
              scale: true, //自适应
              minInterval: 1, //刻度
              axisLabel:{
                  // showMinLabel:false, //不显示最小刻度线值
                  textStyle:{
                      color:'#fff'
                  }
              }
          },
          series: []
      };
      this.numberArr.forEach((item,index) => {
        option.series.push({
            name:this.txtobj[index].planName,
            data: item,
            type: 'bar',
            label: {
                show: true, //开启显示
                position: 'top',
                textStyle: { //数值样式
                    color: 'white',//字体颜色
                    fontSize: 16//字体大小
                }
            }
        })
      })

      this.myChart= echarts.getInstanceByDom(this.$refs.echarts); //有的话就获取已有echarts实例的DOM节点。
      if (this.myChart== null) { // 如果不存在，就进行初始化。
        this.myChart= echarts.init(this.$refs.echarts);
      }else{
        this.myChart.clear()
      }
      option && this.myChart.setOption(option);
    },
  },
}
</script>
<style lang="scss" scoped>
.home{
  width:100%;
  height:100%;
  .home_box{
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    .home_box_li{
      width: 50%;
      height:50%;
      padding:12px;
      box-sizing: border-box;
    }
  }
  .show_home{
    text-align: center;
    h1{
      font-size:32px;
      text-align: center;
      margin:20px 0 0px;
      font-weight: bold;
      letter-spacing: 10px;
    }
    img{
      width: 260px;
      height:260px;
    }
    .show_home_ul{
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items:center;
      li{
        margin:4px;
        text-align: center;
        border:1px solid #fff;
        width:calc(50% - 8px);
        height:50%;
        // line-height: 50%;
        border-radius: 8px;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p{
          font-size: 20px;
        }
        img{
          margin-bottom:8px;
          width: 40px;
          height:40px;
          // margin-bottom:8px;
        }
        &:hover{
          background: #ccc;
        }
      }
    }
  }
  .el-header{
    padding:0;
  }
  .el-container{
    height:100%;
  }
  .mainContent{
    height: calc(100vh - 45px);
    .main_sidebar{
      width:260px;
      border-right:1px solid #fff;
      box-sizing: border-box;
    }
    .mainShowContent{
      width: calc(100% - 260px);
      .showContent{
        background-color: rgba(255,255,255,.2);
        height: calc(100% - 40px);
        overflow-y: auto;
        padding:10px;
        box-sizing: border-box;
      }
    }
  }
}

.table_flex_column .table_con{
    height: 100%;
}
</style>
