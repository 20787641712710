import axios from "axios"
import { Message } from 'element-ui'
import router from '@/router'
import store from '@/store'
import { getToken,X_getToken,setToken,X_setToken } from '@/utils/auth'
import api from './rApi'
//创建axios的实例
const service=axios.create({
	baseURL:api.url + '/api',
	timeout:api.timeout
})

//拦截器-请求前
service.interceptors.request.use(function(config){
	//登录后，携带token请求数据
	if (getToken()) { 
		config.headers['Authorization'] = 'Bearer ' + getToken()
        config.headers['X-Authorization'] = 'Bearer ' + X_getToken()
		// config.headers['X-access-token'] = 'Bearer ' + 
	}
	return config;
},error=>{
	return Promise.reject(error);
})
//拦截器-请求后的返回
service.interceptors.response.use(response=>{
	// 更新最新的token，防止token过期
	if (getToken() && response.headers['x-access-token'] && response.headers['access-token']) {
		setToken(response.headers['access-token']);
		X_setToken(response.headers['x-access-token']);
	}

	if(response.status==200){
		if(response.config.url === "/user/login"){  //登录要获取token
			return Promise.resolve(response)
		}
		return Promise.resolve(response.data)
	}else{
		return Promise.reject(response);
	}
	
},error=>{
	console.log(error);
	if(error && error.response && error.response.status){
		switch(error.response.status){
			case 401:
				Message({
					message: '身份验证过期，请重新登录',
					type: 'error',
					duration: 5 * 1000
				})
				// localStorage.clear("token");
				localStorage.clear();
				// store.commit('DEL_ALL_VIEWS')
				router.push({ path: "/login" });
				
			break;
			
			case "Network Error":
				Message({
					message: '服务器没运行！',
					type: 'error',
					duration: 5 * 1000
				})
			break;
			
			case 404:
				alert("资源不存在");
			break;
			
			default:
				// alert(error);
			
		}
		return error.response;
	}else{
		switch(error.code){
			case "ERR_NETWORK":
				Message({
					message: '服务器没运行！',
					type: 'error',
					duration: 3 * 1000
				})
			case "ECONNABORTED":
				Message({
					message: error.message,
					type: 'error',
					duration: 3 * 1000
				})
			break;
		}
		error.data = {}
		return error
	}
})

export default service;
