import {
    Step,
    Steps, 
    Button, 
    Option, 
    OptionGroup,
    Select, 
    Switch, 
    MessageBox, 
    Message,
    Form,
    FormItem,
    Input,
    Container,
    Header,
    Aside,
    Main,
    Checkbox,
    CheckboxGroup,
    Menu,
    Submenu,
    MenuItemGroup,
    MenuItem,
    Breadcrumb,
    BreadcrumbItem,
    Table,
    TableColumn,
    Dialog,
    Cascader,
    Loading,
    Pagination,
    Slider,
    Progress,
    Drawer,
    Tag,
    Link,
    Empty,
    Tree,
    Tooltip,
    Radio,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Upload,
    Tabs,
    TabPane,
    DatePicker,
    Timeline,
    TimelineItem,
    Card,
    Row,
    Popover,
    InputNumber,
    RadioGroup,
    RadioButton,
    Col

} from 'element-ui'
const element = {
    install: function(Vue) {
        Vue.use(Col)
        Vue.use(Step)
        Vue.use(Steps)
        Vue.use(RadioGroup)
        Vue.use(RadioButton)
        Vue.use(OptionGroup)
        Vue.use(InputNumber)
        Vue.use(Popover)
        Vue.use(Row)
        Vue.use(Timeline)
        Vue.use(TimelineItem)
        Vue.use(Card)
        Vue.use(DatePicker)
        Vue.use(Tabs)
        Vue.use(TabPane)
        Vue.use(Upload)
        Vue.use(Dropdown)
        Vue.use(DropdownMenu)
        Vue.use(DropdownItem)
        Vue.use(Radio)
        Vue.use(Tooltip)
        Vue.use(Tree)
        Vue.use(Empty)
        Vue.use(Link)
        Vue.use(Tag)
        Vue.use(Drawer)
        Vue.use(Progress)
        Vue.use(Slider)
        Vue.use(Pagination)
        Vue.use(Cascader)
        Vue.use(Dialog)
        Vue.use(Button)
        Vue.use(Switch)
        Vue.use(Select)
        Vue.use(Option)
        Vue.use(Form)
        Vue.use(FormItem)
        Vue.use(Input)
        Vue.use(Container)
        Vue.use(Header)
        Vue.use(Aside)
        Vue.use(Main)
        Vue.use(Checkbox)
        Vue.use(CheckboxGroup)
        Vue.use(Menu)
        Vue.use(Submenu)
        Vue.use(MenuItemGroup)
        Vue.use(MenuItem)
        Vue.use(Breadcrumb)
        Vue.use(BreadcrumbItem)
        Vue.use(Table)
        Vue.use(TableColumn)
        Vue.use(Loading)
       	// 往vue实例原型里添加消息提示，方便全局调用
        Vue.prototype.$msgbox = MessageBox
        Vue.prototype.$alert = MessageBox.alert
        Vue.prototype.$confirm = MessageBox.confirm
        Vue.prototype.$message = Message
        //使用：this.$message('这是一条消息提示');
    }
}
export default element