 const modeData = {
    modeObjTip:[
        {
            id:1,
            title:"模式一：95式半自动步枪VS无人靶车（装载人型靶标）",
            content:"受训学员手持95式半自动步枪于固定点位，无人靶车（装载人型靶标）或靶车编队按预先设计路线进行机动。单人或按班组编队于固定射击点位，采用跪姿或匍匐射击姿态，对运动中无人靶车或靶车编队进行射击训练。"
        },
        {
            id:2,
            title:"模式二：激光模拟设备VS无人靶车（装载激光模拟设备）",
            content:"受训学员手持激光模拟枪械，身穿激光感应护具，激光模拟枪械可发射激光模拟射击，激光感应护具被激光照射击中后，可模拟中单效果，并将中弹位置、中弹时间等信息无线传送到后台数据终端，进行记录。无人靶车装载人形靶标和激光发射器，靶标着激光感应护具，靶车可以模拟真人机动，靶标装载的激光发射器模拟真人进行反击射击。受训学员按单人或编队顺序进入对抗射击训练场地，与单无人靶车进行对抗射击训练。"
        },
        {
            id:3,
            title:"模式三：激光模拟设备VS激光模拟设备",
            content:"受训学员手持激光模拟枪械，身穿激光感应护具，激光模拟枪械可发射激光模拟射击，激光感应护具被激光照射击中后，可模拟中单效果，并将中弹位置、中弹时间等信息无线传送到后台数据终端，进行记录。受训学员按顺序进入对抗射击训练场地，开展单人或编队移动对抗射击训练。"
        }
    ],
    modeTip:["受训学员手持95式半自动步枪于固定点位，无人靶车（装载人型靶标）或靶车编队按预先设计路线进行机动。单人或按班组编队于固定射击点位，采用跪姿或匍匐射击姿态，对运动中无人靶车或靶车编队进行射击训练。","受训学员手持激光模拟枪械，身穿激光感应护具，激光模拟枪械可发射激光模拟射击，激光感应护具被激光照射击中后，可模拟中单效果，并将中弹位置、中弹时间等信息无线传送到后台数据终端，进行记录。无人靶车装载人形靶标和激光发射器，靶标着激光感应护具，靶车可以模拟真人机动，靶标装载的激光发射器模拟真人进行反击射击。受训学员按单人或编队顺序进入对抗射击训练场地，与单无人靶车进行对抗射击训练。","受训学员手持激光模拟枪械，身穿激光感应护具，激光模拟枪械可发射激光模拟射击，激光感应护具被激光照射击中后，可模拟中单效果，并将中弹位置、中弹时间等信息无线传送到后台数据终端，进行记录。受训学员按顺序进入对抗射击训练场地，开展单人或编队移动对抗射击训练。"],
    subjectTip:[
        {   
            id:1,
            children:[
                {
                    title:"条件",
                    content:[
                        "训练教材；电教器材；95式自动步枪；5.8mm普通弹（平均每人消耗不少于15发）；场地要素标识牌、旗帜；模拟战场音效；发烟器材；人体部位靶；战斗着装；射击训练场、实弹射击场；设置坑、包、坎、沟渠、树桩、矮墙、门窗等地物。"
                    ]
                },
                {
                    title:"内容",
                    content:[
                        "1.对横（斜）、纵方向运动目标射击方法",
                        "2.选择确定射击部位与时机",
                        "3.按指令射击"
                    ]
                },
                {
                    title:"标准",
                    content:[
                        "1.掌握自动步枪对运动目标的射击方法。",
                        "2.能够根据目标性质、地形情况正确选择射击位置和姿势。",
                        "3.能够根据目标性质及任务选择射击部位与射击   时机。",
                        "4.掌握射击技能，能够准确命中目标。",
                        "5.掌握对人体部位射击技能，能够准确命中目标。",
                    ]
                },
                {
                    title:"方法",
                    content:[
                        "相关理论内容，通常采取个人自学与集体讲授相结合的方法组织；实弹射击，通常按照讲解示范、技能练习、体验射击、矫正练习、实弹检验的步骤组织实施，其中实弹体验射击训练应不少于2次；在组织实弹体验射击和检验射击时，指挥员应以战术情况诱导射手完成机动、观察、占领射击位置、射击等动作。"
                    ]
                },
                {
                    title:"考核",
                    content:[
                        "承训单位组织实施。考核实弹射击。",
                        "条件要求：采取卧姿或掩体内射击方式，在200～300米距离内，移动人体部位靶，使用弹数5发。自下达“开始射击”口令起，在10分钟内射击完毕。",
                        "组织程序：射手在指挥员组织下，独立完成领取子弹、向弹匣内装子弹、向射击地线机动等动作；自下达“开始射击”口令起，对目标进行瞄准。指挥员随机下达“一、二、三、对×部位射击”或“一、二、对×部位射击”的指令，射手每听到1次射击指令后，完成一次击发，时间不超过3秒，并能命中指定射击部位，不报靶；最后一个目标射击完毕后，组织验枪，报靶。",
                        "成绩评定：命中40-45环为优秀，命中35-40环为良好，命中28-35发为及格，否则为不及格。单发射超出规定时间不计入成绩。",
                    ]
                },

            ]
        },
        {   
            id:2,
            children:[
                {
                    title:"条件",
                    content:[
                        "训练教材；电教器材；95式自动步枪；5.8mm普通弹（平均每人消耗不少于15发）；场地要素标识牌、旗帜；模拟战场音效；发烟器材；人体部位靶；战斗着装；射击训练场、实弹射击场；设置坑、包、坎、沟渠、树桩、矮墙、门窗等地物。"
                    ]
                },
                {
                    title:"内容",
                    content:[
                        "1.对横（斜）、纵方向运动目标射击方法",
                        "2.选择确定射击部位与时机",
                        "3.按指令射击"
                    ]
                },
                {
                    title:"标准",
                    content:[
                        "1.掌握自动步枪对运动目标的射击方法。",
                        "2.能够根据目标性质、地形情况正确选择射击位置和姿势。",
                        "3.能够根据目标性质及任务选择射击部位与射击   时机。",
                        "4.掌握射击技能，能够准确命中目标。",
                        "5.掌握对人体部位射击技能，能够准确命中目标。",
                    ]
                },
                {
                    title:"方法",
                    content:[
                        "相关理论内容，通常采取个人自学与集体讲授相结合的方法组织；实弹射击，通常按照讲解示范、技能练习、体验射击、矫正练习、实弹检验的步骤组织实施，其中实弹体验射击训练应不少于2次；在组织实弹体验射击和检验射击时，指挥员应以战术情况诱导射手完成机动、观察、占领射击位置、射击等动作。"
                    ]
                },
                {
                    title:"考核",
                    content:[
                        "承训单位组织实施。考核实弹射击。",
                        "条件要求：采取卧姿或掩体内射击方式，在200～300米距离内，移动人体部位靶，使用弹数5发。自下达“开始射击”口令起，在10分钟内射击完毕。",
                        "组织程序：射手在指挥员组织下，独立完成领取子弹、向弹匣内装子弹、向射击地线机动等动作；自下达“开始射击”口令起，对目标进行瞄准。指挥员随机下达“一号位对×单位×部位射击”或“一、二号位对×单位对×部位射击”的指令，射手每听到1次射击指令后，完成一次击发，时间不超过3秒，并能命中指定射击部位，不报靶；最后一个目标射击完毕后，组织验枪，报靶。",
                        "成绩评定：命中40-45环为优秀，命中35-40环为良好，命中28-35发为及格，否则为不及格。单发射超出规定时间不计入成绩。",
                    ]
                },

            ]
        },
        {   
            id:3,
            children:[
                {
                    title:"条件",
                    content:[
                        "训练教材；电教器材；95式自动步枪；5.8mm普通弹（平均每人消耗不少于15发）；场地要素标识牌、旗帜；模拟战场音效；发烟器材；人体部位靶；战斗着装；射击训练场、实弹射击场；设置坑、包、坎、沟渠、树桩、矮墙、门窗等地物。"
                    ]
                },
                {
                    title:"内容",
                    content:[
                        "1.选定编队固定点位位置",
                        "2.确定消灭目标顺序",
                        "3.组织所属火器打击目标"
                    ]
                },
                {
                    title:"标准",
                    content:[
                        "1.能够根据战场目标性质正确选定编队点位位置。",
                        "2.能够根据目标性质、作战任务确定消灭目标顺序。",
                        "3.能够指挥编队成员充分利用所属位置优势，根据选择的打击目标火器以及消灭目标的顺序打击目标。",
                    ]
                },
                {
                    title:"方法",
                    content:[
                        "通常按照理论提示、连贯示范、分步讲解、研究讨论、组织练习、考核验收的步骤进行，考核验收通常由考核员以战术情况诱导完成作业；受训者统一以指挥员身份参加。"
                    ]
                },
                {
                    title:"考核",
                    content:[
                        "由承训单位组织，考核全部内容，参考人员以班长身份参加考核，以班为单位连贯实施，由区分组织指挥、协同行动、技能运用3个方面制定评分细则，按四级制评定，90%以上达到标准为优秀，70%以上达到标准为良好，60%以上达到标准为及格，否则为不及格",
                    ]
                },

            ]
        },
        {   
            id:4,
            children:[
                {
                    title:"条件",
                    content:[
                        "训练教材；电教器材；95式激光模拟枪械；激光感应护具；场地要素标识牌、旗帜；模拟战场音效；发烟器材；人体部位靶；战斗着装；射击训练场、实弹射击场；设置坑、包、坎、沟渠、树桩、矮墙、门窗等地物。"
                    ]
                },
                {
                    title:"内容",
                    content:[
                        "1.个人战术机动前进方法",
                        "2.选择确定射击地点与时机 "
                    ]
                },
                {
                    title:"标准",
                    content:[
                        "1.掌握自动步枪对运动目标的射击方法。",
                        "2.能够根据目标性质、地形情况正确选择射击位置和姿势。",
                        "3.能够根据目标性质及任务选择射击部位与射击时机。",
                        "4.掌握射击技能，能够准确命中目标。"
                    ]
                },
                {
                    title:"方法",
                    content:[
                        "相关理论内容，通常采取个人自学与集体讲授相结合的方法组织；实弹射击，通常按照讲解示范、技能练习、体验射击、矫正练习、对抗检验的步骤组织实施，其中对抗体验射击训练应不少于5次；在组织实弹体验射击和检验射击时，指挥员应以战术情况诱导射手完成机动、观察、占领射击位置、射击等动作。"
                    ]
                },
                {
                    title:"考核",
                    content:[
                        "承训单位组织实施。考核激光模拟对抗射击。",
                        "条件要求：采取低姿前进或选择掩体内射击方式，在1000×1000米区域内，移动前进，对对抗目标进行射击，使用激光模拟弹数5发。自下达“开始对抗”口令起，在20分钟内射击完毕。",
                        "组织程序：射手在指挥员组织下，独立完成领取枪械装备准备动作；自下达“开始对抗”口令起，分别进入对抗区域场地，主动寻找目标，并伺机对目标进行瞄准。射手找到对抗目标后，自行瞄准完成一次击发，击中目标后，激光穿戴设备可呈现烟雾、声响提示；受训双方对抗射击结果显示完毕后，组织验枪，报靶。",
                        "成绩评定：进行五次单人对抗训练，命中40-45环为优秀，命中35-40环为良好，命中28-35发为及格，否则为不及格。超出规定时间未射击不计入成绩。",
                    ]
                },

            ]
        },
        {   
            id:5,
            children:[
                {
                    title:"条件",
                    content:[
                        "训练教材；电教器材；95式激光模拟枪械；激光感应护具；场地要素标识牌、旗帜；模拟战场音效；发烟器材；人体部位靶；战斗着装；射击训练场、实弹射击场；设置坑、包、坎、沟渠、树桩、矮墙、门窗等地物。"
                    ]
                },
                {
                    title:"内容",
                    content:[
                        "1.编队战术机动前进方法",
                        "2.选择确定射击地点与时机"
                    ]
                },
                {
                    title:"标准",
                    content:[
                        "1.掌握自动步枪对运动目标的射击方法。",
                        "2.能够根据目标性质、地形情况正确选择射击位置和姿势。",
                        "3.能够根据目标性质及任务选择射击目标、部位与射击时机。",
                        "4.掌握射击技能，能够准确命中目标。",
                        "5.掌握对人体部位射击技能，能够准确命中目标。"
                    ]
                },
                {
                    title:"方法",
                    content:[
                        "相关理论内容，通常采取个人自学与集体讲授相结合的方法组织；实弹射击，通常按照讲解示范、技能练习、体验射击、矫正练习、对抗检验的步骤组织实施，其中对抗体验射击训练应不少于5次；在组织实弹体验射击和检验射击时，指挥员应以战术情况诱导射手完成机动、观察、占领射击位置、射击等动作。"
                    ]
                },
                {
                    title:"考核",
                    content:[
                        "承训单位组织实施。考核激光模拟对抗射击。",
                        "条件要求：采取低姿前进或选择掩体内射击方式，在1000×1000米区域内，移动前进，对对抗目标进行射击，使用激光模拟弹数5发。自下达“开始对抗”口令起，在20分钟内射击完毕。",
                        "组织程序：射手在指挥员组织下，独立完成领取枪械装备准备动作；自下达“开始对抗”口令起，分别进入对抗区域场地，主动寻找目标，并伺机对目标进行瞄准。射手找到对抗目标后，自行瞄准完成一次击发，击中目标后，激光穿戴设备可呈现烟雾、声响提示；受训编队双方对抗射击结果显示完毕后，组织验枪，报靶。",
                        "成绩评定：进行五次编队对抗训练，命中40-45环为优秀，命中35-40环为良好，命中28-35发为及格，否则为不及格。超出规定时间未射击不计入成绩。",
                    ]
                },

            ]
        },
        {   
            id:6,
            children:[
                {
                    title:"条件",
                    content:[
                        "训练教材；电教器材；95式激光模拟枪械；激光感应护具；场地要素标识牌、旗帜；模拟战场音效；发烟器材；人体部位靶；战斗着装；射击训练场、实弹射击场；设置坑、包、坎、沟渠、树桩、矮墙、门窗等地物。"
                    ]
                },
                {
                    title:"内容",
                    content:[
                        "1.选定编队机动前进方式和隐蔽位置",
                        "2.侦察对抗目标位置",
                        "3.确定消灭目标顺序",
                        "4.组织所属火器打击目标"
                    ]
                },
                {
                    title:"标准",
                    content:[
                        "1.能够根据战场目标性质正确选定编队机动前进方式和隐蔽位置。",
                        "2.能够主动侦察对抗目标位置",
                        "3.能够根据目标性质、作战任务确定消灭目标顺序。",
                        "4.能够指挥编队成员充分利用所属位置优势，根据选择的打击目标火器以及消灭目标的顺序打击目标。"
                    ]
                },
                {
                    title:"方法",
                    content:[
                        "通常按照理论提示、连贯示范、分步讲解、研究讨论、组织练习、考核验收的步骤进行，考核验收通常由考核员以战术情况诱导完成作业；受训者统一以指挥员身份参加。"
                    ]
                },
                {
                    title:"考核",
                    content:[
                        "由承训单位组织，考核全部内容，参考人员以班长身份参加考核，以班为单位连贯实施，由区分组织指挥、协同行动、技能运用3个方面制定评分细则，按四级制评定，90%以上达到标准为优秀，70%以上达到标准为良好，60%以上达到标准为及格，否则为不及格",
                    ]
                },

            ]
        },
        {   
            id:7,
            children:[
                {
                    title:"条件",
                    content:[
                        "训练教材；电教器材；95式激光模拟枪械；激光感应护具；场地要素标识牌、旗帜；模拟战场音效；发烟器材；无人靶车；人体部位靶；战斗着装；射击训练场、实弹射击场；设置坑、包、坎、沟渠、树桩、矮墙、门窗等地物。"
                    ]
                },
                {
                    title:"内容",
                    content:[
                        "1.个人战术机动前进方法",
                        "2.选择确定射击地点与时机 "
                    ]
                },
                {
                    title:"标准",
                    content:[
                        "1.掌握自动步枪对运动目标的射击方法。",
                        "2.能够根据目标性质、地形情况正确选择射击位置和姿势。",
                        "3.能够根据目标性质及任务选择射击部位与射击时机。",
                        "4.掌握射击技能，能够准确命中目标。",
                        "5.掌握对人体部位射击技能，能够准确命中目标。"
                    ]
                },
                {
                    title:"方法",
                    content:[
                        "相关理论内容，通常采取个人自学与集体讲授相结合的方法组织；实弹射击，通常按照讲解示范、技能练习、体验射击、矫正练习、对抗检验的步骤组织实施，其中对抗体验射击训练应不少于5次；在组织实弹体验射击和检验射击时，指挥员应以战术情况诱导射手完成机动、观察、占领射击位置、射击等动作。"
                    ]
                },
                {
                    title:"考核",
                    content:[
                        "承训单位组织实施。考核激光模拟对抗射击。",
                        "条件要求：采取低姿前进或选择掩体内射击方式，在1000×1000米区域内，移动前进，对对抗目标进行射击，使用激光模拟弹数5发。自下达“开始对抗”口令起，在20分钟内射击完毕。",
                        "组织程序：射手在指挥员组织下，独立完成领取枪械装备准备动作；自下达“开始对抗”口令起，分别进入对抗区域场地，主动寻找目标，并伺机对目标进行瞄准。射手找到对抗目标后，自行瞄准完成一次击发，击中目标后，激光穿戴设备可呈现烟雾、声响提示；受训双方对抗射击结果显示完毕后，组织验枪，报靶。",
                        "成绩评定：进行五次单人对抗训练，命中40-45环为优秀，命中35-40环为良好，命中28-35发为及格，否则为不及格。超出规定时间未射击不计入。"
                    ]
                },

            ]
        },
        {   
            id:8,
            children:[
                {
                    title:"条件",
                    content:[
                        "训练教材；电教器材；95式激光模拟枪械；激光感应护具；场地要素标识牌、旗帜；模拟战场音效；发烟器材；无人靶车；人体部位靶；战斗着装；射击训练场、实弹射击场；设置坑、包、坎、沟渠、树桩、矮墙、门窗等地物。"
                    ]
                },
                {
                    title:"内容",
                    content:[
                        "1.编队战术机动前进方法",
                        "2.选择确定射击地点与时机"
                    ]
                },
                {
                    title:"标准",
                    content:[
                        "1.掌握自动步枪对运动目标的射击方法。",
                        "2.能够根据目标性质、地形情况正确选择射击位置和姿势。",
                        "3.能够根据目标性质及任务选择射击部位与射击时机。",
                        "4.掌握射击技能，能够准确命中目标。",
                        "5.掌握对人体部位射击技能，能够准确命中目标。"
                    ]
                },
                {
                    title:"方法",
                    content:[
                        "相关理论内容，通常采取个人自学与集体讲授相结合的方法组织；实弹射击，通常按照讲解示范、技能练习、体验射击、矫正练习、对抗检验的步骤组织实施，其中对抗体验射击训练应不少于5次；在组织实弹体验射击和检验射击时，指挥员应以战术情况诱导射手完成机动、观察、占领射击位置、射击等动作。"
                    ]
                },
                {
                    title:"考核",
                    content:[
                        "承训单位组织实施。考核激光模拟对抗射击。",
                        "条件要求：采取低姿前进或选择掩体内射击方式，在1000×1000米区域内，移动前进，对对抗目标进行射击，使用激光模拟弹数5发。自下达“开始对抗”口令起，在20分钟内射击完毕。",
                        "组织程序：射手在指挥员组织下，独立完成领取枪械装备准备动作；自下达“开始对抗”口令起，分别进入对抗区域场地，主动寻找目标，并伺机对目标进行瞄准。射手找到对抗目标后，自行瞄准完成一次击发，击中目标后，激光穿戴设备可呈现烟雾、声响提示；受训编队双方对抗射击结果显示完毕后，组织验枪，报靶。",
                        "成绩评定：进行五次编队对抗训练，命中40-45环为优秀，命中35-40环为良好，命中28-35发为及格，否则为不及格。超出规定时间未射击不计入成绩。"
                    ]
                },

            ]
        },
        {   
            id:9,
            children:[
                {
                    title:"条件",
                    content:[
                        "训练教材；电教器材；95式激光模拟枪械；激光感应护具；场地要素标识牌、旗帜；模拟战场音效；发烟器材；无人靶车；人体部位靶；战斗着装；射击训练场、实弹射击场；设置坑、包、坎、沟渠、树桩、矮墙、门窗等地物。"
                    ]
                },
                {
                    title:"内容",
                    content:[
                        "1.选定编队机动前进方式和隐蔽位置",
                        "2.侦察对抗目标位置",
                        "3.确定消灭目标顺序",
                        "4.组织所属火器打击目标"
                    ]
                },
                {
                    title:"标准",
                    content:[
                        "1.能够根据战场目标性质正确选定编队机动前进方式和隐蔽位置。",
                        "2.能够主动侦察对抗目标位置。",
                        "3.能够根据目标性质、作战任务确定消灭目标顺序。",
                        "4.能够指挥编队成员充分利用所属位置优势，根据选择的目标火器以及消灭目标的顺序打击目标。"
                    ]
                },
                {
                    title:"方法",
                    content:[
                        "通常按照理论提示、连贯示范、分步讲解、研究讨论、组织练习、考核验收的步骤进行，考核验收通常由考核员以战术情况诱导完成作业；受训者统一以指挥员身份参加。"
                    ]
                },
                {
                    title:"考核",
                    content:[
                        "由承训单位组织，考核全部内容，参考人员以班长身份参加考核，以班为单位连贯实施，由区分组织指挥、协同行动、技能运用3个方面制定评分细则，按四级制评定，90%以上达到标准为优秀，70%以上达到标准为良好，60%以上达到标准为及格，否则为不及格。"
                    ]
                },

            ]
        }
    ]
}
export default modeData