const tokenStr = "token"
const xTokenStr = "x-token"

export function getToken() {
  return localStorage.getItem(tokenStr)
}

export function setToken(token) {
  return localStorage.setItem(tokenStr,token)
}

export function removeToken() {
  return localStorage.clear(tokenStr)
}

export function X_getToken() {
  return localStorage.getItem(xTokenStr)
}

export function X_setToken(token) {
  return localStorage.setItem(xTokenStr,token)
}

export function X_removeToken() {
  return localStorage.clear(xTokenStr)
}
