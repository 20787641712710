const routerData = {
    state: {
        initRouterData:[],
    },
    mutations:{
        GET_ROUTER_DATA(state,data){
            state.initRouterData = data
        }
    }
}
  
  export default routerData