import request from "./request"

const dic = {
	// 获取字典列表
	dicList(params){
		return request({
		    url: "/dic-item/query",
		    method: "get",
		    params
		})
	},
	// 创建字典
	add(data){
		return request({
		    url: "/dic-item/create",
		    method: "post",
		    data
		})
	},
	// 删除字典
	delete(data){
		return request({
		    url: "/dic-item/delete",
		    method: "delete",
		    data
		})
	},
	// 更新字典
	edit(data){
		return request({
		    url: "/dic-item/update-dic-item",
		    method: "put",
		    data
		})
	},
	// 详情
	detail(data){
		return request({
		    url: "/dic-item/load",
		    method: "post",
		    data
		})
	},
}
export default dic