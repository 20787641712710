import Vue from 'vue'
import Vuex from 'vuex'

import tagsView from './modules/tagsView'
import falseData from './modules/falseData'
import routerData from './modules/routerData'
import testTrainData from './modules/testTrainData'
import modeData from './modules/modeData'

import getters from './getters'

import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)
export default new Vuex.Store({
  state:{
    userInfo:JSON.parse(localStorage.getItem("userInfo")) || {},
    modeData:modeData
  },
  mutations:{
    // 修改用户信息
    edit_userInfo(state,data){
      localStorage.setItem("userInfo",JSON.stringify(data))
      state.userInfo = data
    }
  },
  actions:{
    // 获取用户信息
    get_userInfo(){}
  },

  getters,
  modules: {
    tagsView,
    falseData,
    routerData,
    testTrainData,
    // modeData,
  },
   /* vuex数据持久化配置 */
  plugins: [
    createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies
      storage: window.localStorage,
      // 存储的 key 的key值
      key: "store",
      reducer(state) { //render错误修改
        // 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
        return { ...state };
      }
    })
  ]
})
