import request from "./request"

const user = {
	// 登录-----会返回用户信息
	login(data){
		return request({
		    url: "/user/login",
		    method: "post",
		    data:data
		})
	},
	// 获取用户登录信息
	loginInfo(params){
		return request({
		    url: "/user/load-current-user",
		    method: "get",
		    params
		})
	},
	// 获取用户列表
	userList(params){
		return request({
		    url: "/user/query",
		    method: "get",
		    params
		})
	},
	// 加载用户
	load(data){
		return request({
		    url: "/user/load",
		    method: "post",
		    data
		})
	},
	// 创建用户
	add(data){
		return request({
		    url: "/user/create",
		    method: "post",
		    data
		})
	},
	// 删除用户
	delete(data){
		return request({
		    url: "/user/delete",
		    method: "delete",
		    data
		})
	},
	// 更新用户密码
	editPwd(data){
		return request({
		    url: "/user/update-user",
		    method: "put",
		    data
		})
	},
	// 分配班级
	assignOrganization(data){
		return request({
		    url: "/user/assign-organization",
		    method: "post",
		    data
		})
	},
	// 分配角色
	assignRole(data){
		return request({
		    url: "/user/assign-role",
		    method: "post",
		    data
		})
	},
	// 取消分配角色
	noAssignRole(data){
		return request({
		    url: "/user/un-assign-role",
		    method: "post",
		    data
		})
	},
	// 获取用户信息列表
	// userInfoList(params){
	// 	return request({
	// 	    url: "/user/user-info-list",
	// 	    method: "get",
	// 	    params
	// 	})
	// },
	// 获取用户信息
	// userInfo(params){
	// 	return request({
	// 	    url: "/user/user-info-list",
	// 	    method: "get",
	// 	    params
	// 	})
	// },
	// 增加或修改用户信息
	editUserInfo(data){
		return request({
		    url: "/user/update-user-profile",
		    method: "put",
		    data
		})
	},
	// 删除用户信息
	delUserInfo(data){
		return request({
		    url: "/user/delete-user-profile",
		    method: "delete",
		    data
		})
	},

	// 根据班级获取用户 班级id
	organizationUser(params){
		return request({
		    url: "/organization/query-organization-users",
		    method: "get",
		    params
		})
	},

	// 导出用户数据----execl
	// exportUser(data){
	// 	return request({
	// 	    url: "user/export-user-info-to-excel",
	// 	    method: "post",
	// 	    data,
	// 		responseType: 'blob'
	// 	})
	// },
	// 导出用户数据----返回数据
	// exportUserInfo(data){
	// 	return request({
	// 	    url: "user/export-user-info",
	// 	    method: "post",
	// 	    data,
	// 	})
	// },
}

export default user