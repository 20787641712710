import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home/Home'
import Login from '@/views/Login/index'

Vue.use(VueRouter)

// 解决导航重复
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    component: Home,
    redirect:'home',
    name: 'layout',
    meta:{sortNo: 0},
    children:[     
      {
        path:'/home',
        name:'home',
        meta:{title:'首页',icon:'el-icon-s-home'}
      },
      {
        //梅珊测试，20240820
        path:'/test',
        name:'test',
        meta:{title:'计划-评估-可视化向导',icon:'el-icon-s-home',keepAlive:true},
        component: () => import('../views/TrainingPlanWizard/index.vue')
      },
      // 训练模式是
      {
        path: '/TrainingManager',
        name: 'TrainingManager',
        meta:{title:'训练模式',icon:'',father:'trainManage'},
        component: () => import('../views/TrainingManager/index.vue')
      },
      {
        path: '/EvaluationManager',
        name: 'EvaluationManager',
        meta:{title:'评估指标体系模板库',icon:'',father:'trainManage'},
        component: () => import('../views/EvaluationManager/index.vue')
      },
      {
        path: '/EvaluationManager/add',
        name: 'EvaluationManager/add',
        meta:{title:'评估指标模板-操作',icon:'',noFater:true},  // noFater操作页面不关联侧边栏
        component: () => import('../views/EvaluationManager/add.vue')
      },
      // {
      //   path: '/EvaluationManager/edit',
      //   name: 'EvaluationManager/edit',
      //   meta:{title:'评估指标模板-修改',icon:'',noFater:true},
      //   component: () => import('../views/EvaluationManager/edit.vue')
      // },
      // 教学训练服务
      {
        path: '/Service',
        name: 'Service',
        meta:{title:'训练计划安排',icon:'',father:'trainingService'},
        component: () => import('../views/Service/index.vue')
      },
      {
        path: '/Service/add',
        name: 'Service/add',
        meta:{title:'训练计划安排-操作',icon:'',noFater:true},
        component: () => import('../views/Service/add.vue')
      },
      {
        path: '/Service/inquire',
        name: 'Service/inquire',
        meta:{title:'训练日程表',icon:'',father:'trainingService'},
        component: () => import('../views/Service/inquire.vue')
      },
      {  
        path: '/Grade',
        name: 'Grade',
        meta:{title:'训练结果数据导入',icon:'',father:'trainingService',keepAlive:true},
        component: () => import('../views/Grade/index.vue')
      },
      // {
      //   path: '/Service/score',
      //   name: 'Service/score',
      //   meta:{title:'训练成绩',icon:'',father:'trainingService'},
      //   component: () => import('../views/Service/score.vue')
      // },
      {
        path: '/Service/announcement',
        name: 'Service/announcement',
        meta:{title:'训练奖惩公告栏',icon:'',father:'trainingService'},
        component: () => import('../views/Service/announcement.vue')
      },
      {
        path: '/Service/announcementManage',
        name: 'Service/announcementManage',
        meta:{title:'训练奖惩管理',icon:'',father:'trainingService'},
        component: () => import('../views/Service/announcementManage.vue')
      },
      //数据处理和导入
      
      {
        path: '/SynthesisEvaluate',
        name: 'SynthesisEvaluate',
        meta:{title:'综合评估',icon:'',father:'dataDeal'},
        component: () => import('../views/SynthesisEvaluate/index.vue')
      },
      {
        path: '/SynthesisEvaluate/show/:id',
        name: 'SynthesisEvaluate/show',
        meta:{title:'综合评估-展示',icon:'',noFater:true},
        component: () => import('../views/SynthesisEvaluate/show.vue')
      },
      // {
      //   path: '/influenceOld',
      //   name: 'influence',
      //   meta:{title:'old',icon:'',father:'dataDeal'},
      //   component: () => import('../views/SynthesisEvaluate/influence.vue')
      // },
      {
        path: '/influence',
        name: 'influenceGojs',
        meta:{title:'影响训练成绩因素分析',icon:'',father:'dataDeal'},
        component: () => import('../views/SynthesisEvaluate/influenceGojs.vue')
      },
      // 训练分析
      {
        path: '/Analysis',
        name: 'Analysis',
        meta:{title:'个人训练效果曲线',icon:'',father:'trainAnalyze'},
        component: () => import('../views/Analysis/index.vue')
      },
      {
        path: '/Class',
        name: 'Class',
        meta:{title:'班级训练效果对比',icon:'',father:'trainAnalyze'},
        component: () => import('../views/Analysis/Class.vue')
      },
      {
        path: '/TrainEchart/one/:id',
        name: 'TrainEchart/one',
        meta:{title:'课目一',icon:'',noFater:true},
        component: () => import('../views/TrainEchart/one.vue')
      },
      {
        path: '/TrainEchart/two/:id',
        name: 'TrainEchart/two',
        meta:{title:'课目二',icon:'',noFater:true},
        component: () => import('../views/TrainEchart/two.vue')
      },
      {
        path: '/TrainEchart/three/:id',
        name: 'TrainEchart/three',
        meta:{title:'课目三',icon:'',noFater:true},
        component: () => import('../views/TrainEchart/three.vue')
      },
      {
        path: '/TrainEchart/four/:id',
        name: 'TrainEchart/four',
        meta:{title:'课目四',icon:'',noFater:true},
        component: () => import('../views/TrainEchart/four.vue')
      },
      {
        path: '/TrainEchart/five/:id',
        name: 'TrainEchart/five',
        meta:{title:'课目五',icon:'',noFater:true},
        component: () => import('../views/TrainEchart/five.vue')
      },
      {
        path: '/TrainEchart/six/:id',
        name: 'TrainEchart/six',
        meta:{title:'课目六',icon:'',noFater:true},
        component: () => import('../views/TrainEchart/six.vue')
      },
      {
        path: '/TrainEchart/seven/:id',
        name: 'TrainEchart/seven',
        meta:{title:'课目七',icon:'',noFater:true},
        component: () => import('../views/TrainEchart/seven.vue')
      },
      {
        path: '/TrainEchart/eight/:id',
        name: 'TrainEchart/eight',
        meta:{title:'课目八',icon:'',noFater:true},
        component: () => import('../views/TrainEchart/eight.vue')
      },
      {
        path: '/TrainEchart/nine/:id',
        name: 'TrainEchart/nine',
        meta:{title:'课目九',icon:'',noFater:true},
        component: () => import('../views/TrainEchart/nine.vue')
      },
      {
        path: '/TrainEchart/ten/:id',
        name: 'TrainEchart/ten',
        meta:{title:'课目十',icon:'',noFater:true},
        component: () => import('../views/TrainEchart/ten.vue')
      },
      {
        path: '/TrainEchart/eleven/:id',
        name: 'TrainEchart/eleven',
        meta:{title:'课目十一',icon:'',noFater:true},
        component: () => import('../views/TrainEchart/eleven.vue')
      },
      //信息录入
      {
        path: '/MessageManager',
        name: 'MessageManager',
        meta:{title:'信息录入状态',icon:'',father:'infoImport'},
        component: () => import('../views/MessageManager/index.vue')
      },
      {
        path: '/MessageManager/Base',
        name: 'MessageManager/Base',
        meta:{title:'个人综合信息录入',icon:'',father:'infoImport'},
        component: () => import('../views/MessageManager/Base.vue')
      },
      { 
        path: '/MessageManager/Physiology',
        name: 'MessageManager/Physiology',
        meta:{title:'生理指征数据录入',icon:'',father:'infoImport'},
        component: () => import('../views/MessageManager/Physiology.vue')
      },
      { 
        path: '/MessageManager/BigFive',
        name: 'MessageManager/BigFive',
        meta:{title:'大五人格评测',icon:'',father:'infoImport'},
        component: () => import('../views/MessageManager/BigFive.vue')
      },
      { 
        path: '/MessageManager/Screening',
        name: 'MessageManager/Screening',
        meta:{title:'心理筛查',icon:'',father:'infoImport'},
        component: () => import('../views/MessageManager/Screening.vue')
      },
      // 基本信息，系统服务管理
      {  
        path: '/RoleManager',
        name: 'RoleManager',
        meta:{title:'角色管理',icon:'',father:'base'},
        component: () => import('../views/RoleManager/index.vue'),
      },
      {
        path: '/ClassManager',
        name: 'ClassManager',
        meta:{title:'班级管理',icon:'',father:'base'},
        component: () => import('../views/ClassManager/index.vue')
      },
      {
        path: '/UserManager',
        name: 'UserManager',
        meta:{title:'用户管理',icon:'',father:'base'},  //father 和sideBar页面的routerTitle进行配置
        component: () => import('../views/UserManager/index.vue')
      },
      {
        path: '/SiteManager',
        name: 'SiteManager',
        meta:{title:'训练区域管理',icon:'',father:'base'},
        component: () => import('../views/SiteManager/index.vue')
      },
      {
        path: '/LogManager',
        name: 'LogManager',
        meta:{title:'日志管理',icon:'',father:'base'},
        component: () => import('../views/LogManager/index.vue')
      },
      //数据备份管理
      {
        path: '/demo',
        name: 'demo',
        meta:{title:'数据备份',icon:'',father:'base'},
        component: () => import('../views/Demo/index.vue')
      },
      {
        path: '/transmit',
        name: 'transmit',
        meta:{title:'数据传输',icon:'',father:'transmit'},
        component: () => import('../views/Transmit/index.vue')
      },
      {
        path: '/merge',
        name: 'merge',
        meta:{title:'惯导加UWB数据融合分析',icon:'',father:'merge'},
        component: () => import('../views/Merge/index.vue')
      },
    ]
  },
  // {
  //   path: '/trainEchart/one',
  //   name: 'trainEchart/one',
  //   meta:{title:'多人打靶可视化',icon:'',father:'dataAnalysis'},
  //   component: () => import('../views/trainEchart/one.vue')
  // }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
