// 时间戳转换时间
function formatDate(date,format) {
	var gettime = new Date(date)
    let myyear = gettime.getFullYear();
    let mymonth = gettime.getMonth() + 1;
    let myweekday = gettime.getDate();
    let myHours = gettime.getHours()
    let myMinutes = gettime.getMinutes()
    let mySeconds = gettime.getSeconds()

    if (mymonth < 10) {
        mymonth = "0" + mymonth;
    }
    if (myweekday < 10) {
        myweekday = "0" + myweekday;
    }
    if (myHours < 10) {
        myHours = "0" + myHours;
    }
    if (myMinutes < 10) {
        myMinutes = "0" + myMinutes;
    }
    if (mySeconds < 10) {
        mySeconds = "0" + mySeconds;
    }
    let dayNames = ["星期日","星期一","星期二","星期三","星期四","星期五","星期六"]
    let weekday = dayNames[gettime.getDay()]

    switch(format){
        case 'yyyy-MM-DD' : return (myyear + "-" + mymonth + "-" + myweekday);
        break;
        case 'yyyy-MM-DD HH:mm:ss' : return (myyear + "-" + mymonth + "-" + myweekday + " " + myHours + ":" + myMinutes + ":" + mySeconds);
        break;
        case 'HH:mm:ss' : return (myHours + ":" + myMinutes + ":" + mySeconds);
        break;
		case 'MM-DD HH:mm' : return (mymonth + "-" + myweekday + " " + myHours + ":" + myMinutes);
		break;
        case 'getDay' : return weekday;
        break
        case 'yyyy-MM-DD HH:mm:ss.ms' : return (myyear + "-" + mymonth + "-" + myweekday + " " + myHours + ":" + myMinutes + ":" + mySeconds + ":" + date%1000);
        break
    }
}
// yy--MM--DD  hh:mm:ss转时间戳（秒）
function changeTime(time) {
    let Time = new Date(time.replace(/-/g, '/'));
    let timestemp = Time.getTime();
    return timestemp / 1000
}
// s转换为 hh:mm:ss
function dealTime(nowTime){
    const hours = parseInt(nowTime / 3600)
    const minute = parseInt(nowTime / 60 % 60)
    const second = Math.ceil(nowTime % 60)
    const formatSecond = second > 59 ? 59 : second
    return `${hours < 10 ? '0' + hours : hours}:${minute < 10 ? '0' + minute : minute}:${formatSecond < 10 ? '0' + formatSecond : formatSecond}`
}
// hh:mm:ss 变成 s   00:00:10
function dealSecond(timeStr){  
    let timeArr = timeStr.split(":")
    let totalTime = 0
    totalTime = +timeArr[timeArr.length - 1] +  (+timeArr[timeArr.length - 2] * 60)
    if(timeArr.length > 2){
        totalTime += +timeArr[0] *60 *60
    }
    return totalTime
}
// 深拷贝
function deepCopy(params) {
    if (Array.isArray(params)) {
        var res = [];
        for (var i = 0; i < params.length; i++) {
            if (params[i] instanceof Object) {
                res.push(deepCopy(params[i]));
            } else {
                res.push(params[i]);
            }
        }
        return res;
    }
    // 如果是对象 进行 对象的拷贝
    if (params.constructor === Object) {
        var res = {}
        for (var x in params) {
            if (params[x] instanceof Object) {
                res[x] = deepCopy(params[x]);
            } else {
                res[x] = params[x];
            }
        }
        return res
    }
}
// 计算分数递增 
function formulaAdd(xVal,xMin,xMax,yMax=100){
    let yMin = yMax*0.6
    let computeResult = 0
    if(xVal > xMax){
        return yMax
    }
    if(xVal <= xMin){
        return yMin
    }
    computeResult = yMin + (xVal - xMin) * ((yMax-yMin)/(xMax - xMin))
    return computeResult.toFixed(2)
}
// 计算分数递减
function formulaReduce(xVal,xMin,xMax,yMax=100){
    let yMin = yMax*0.6
    let computeResult = 0
    if(xVal == 0){
        return yMin
    }
    if(xVal > xMax){
        return yMin
    }
    if(xVal <= xMin){
        return yMax
    }
    computeResult = yMax - (xVal - xMin) * ((yMax-yMin)/(xMax - xMin))
    return computeResult.toFixed(2)
}

export default {
    formatDate,
    changeTime,
    dealTime,
    dealSecond,
    deepCopy,
    formulaAdd,
    formulaReduce
}