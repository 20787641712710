
export const local = {
  setItem(item, data) {
    localStorage.setItem(`INTERNAL_${item}`, JSON.stringify(data))
  },
  getItem(item) {
    return (localStorage.getItem(`INTERNAL_${item}`) && JSON.parse(localStorage.getItem(`INTERNAL_${item}`))) || ''
  },
  removeItem(item) {
    localStorage.removeItem(`INTERNAL_${item}`)
  },
  removeAll() {
    localStorage.clear()
  }
}